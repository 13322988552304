import "./SizeFinderButton.scss";

import React, { useEffect } from "react";
import { loadFinder } from "./utils";

export type Props = {
    secret: string;
    url: string;
    locale: string;
    product: Product;
};

export type Product = {
    code: string;
    name: string;
    image: string;
};

// See: https://docs.onlinesizing.bike/docs/advanced-integration/confirm-size
export type ConfirmSizeResponse = {
    code: string;
    id: string;
    size: string;
    codeType: number;
};

/**
 * Sizefinder button is an external component. Styles will be loaded after login.
 * Just works in magento "bmc.ddev.site" environment, cause of specified referrer domains.
 */
export function SizeFinderButton({ secret, url, locale, product }: Props) {
    const bodyStyles = getComputedStyle(document.body);
    const primaryColor = bodyStyles.getPropertyValue("--primary-color");
    const ctaText = bodyStyles.getPropertyValue("--primary-cta-text-color");
    const saveResponse = (payload: ConfirmSizeResponse) => {
        sessionStorage.setItem("sizeFinderResult", JSON.stringify(payload));
    };

    useEffect(() => {
        const config = {
            settings: {
                unitsToggle: true,
                apiKey: secret,
                primary: primaryColor,
                cta: primaryColor,
                buttonTextColor: ctaText,
   roundness: 0,
             language: locale,
            },
            events: {
                confirmSize: {
                    callback: saveResponse,
                },
            },
        };

        loadFinder(window, document, "script", "oz", config, url);
    }, [url, primaryColor, secret, ctaText, locale]);

    return (
        <button
            className="oz-trigger"
            data-oz-code={product.code}
            data-oz-name={product.name}
            data-oz-image={product.image}
            data-oz-fullscreen="true"
        >
            Sizefinder
        </button>
    );
}
