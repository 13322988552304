import "./BrandTeaser.scss";

import React from "react";

import { Cta, Props as CTAProps } from "../Cta/Cta";
import { Picture } from "../ResponsivePicture/ResponsivePicture";
import { CreateSpeed } from "./CreateSpeed/CreateSpeed";
import { TeaserPictures } from "./TeaserPictures/TeaserPictures";

export type Props = {
    pictures: Picture[];
    cta: CTAProps;
};

export function BrandTeaser({ pictures, cta }: Props) {
    return (
        <div className="makeover-brand-teaser grid -almost-full">
            <TeaserPictures pictures={pictures} />
            <CreateSpeed />
            <div className="ctacontainer">
                <Cta {...cta} />
            </div>
        </div>
    );
}
