import "./ProductOverview.scss";

import React from "react";
import { Props as ProductProps, Product } from "./Product/Product";
import { ContentSlider } from "../../../../components/ContentSlider/ContentSlider";

export type Props = {
    headline: string;
    products: ProductProps[];
};

// @NOTE: https://splidejs.com/guides/pagination
export function ProductOverview({ headline, products }: Props) {
    const slides = products.map((product, index) => (
        <Product key={index} {...product} />
    ));

    let options = {
        arrows: false,
        perPage: 3,
        gap: "60px",
        breakpoints: {
            767: {
                perPage: 1,
            },
        },
    };

    const paginationMounted = (splide: any, prev: any, next: any) => {
        splide.Components.Pagination.items.forEach(function (item: any) {
            item.button.textContent = `${item.page + 1}`.padStart(2, "0");
        });
    };

    return (
        <section className="adicta-product-overview grid -almost-full">
            <h1 className="headline">{headline}</h1>
            <ContentSlider
                children={slides}
                options={options}
                onPaginationMounted={paginationMounted}
            />
        </section>
    );
}
