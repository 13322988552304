import "./Moodboard.scss";

import React, { useState } from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { useMQ1 } from "../../../../shared/hooks/isMobile";
import { ScorImageSlider, Types } from "../ScorImageSlider/ScorImageSlider";

export type Props = {
    headline: string;
    headlineColor: string;
    pictures: Picture[];
};

export function Moodboard({ headline, headlineColor, pictures }: Props) {
    const [showGallery, setshowGallery] = useState(false);
    const isMQ1 = useMQ1();

    const images = pictures.map((picture, index) => (
        <div className="mood" key={index} onClick={() => setshowGallery(true)}>
            <ResponsivePicture {...picture} />
        </div>
    ));

    const gallery = <ScorImageSlider pictures={pictures} type={Types.Bottom} />;

    const galleryMobile = (
        <ScorImageSlider pictures={pictures} type={Types.Top} />
    );

    const moodboard = <div className="moods">{images}</div>;

    const desktop = showGallery ? gallery : moodboard;

    return (
        <section className="scor-moodboard scor-grid -almost-full">
            <h1 className="headline" style={{ color: headlineColor }}>
                {headline}
            </h1>
            {!isMQ1 && desktop}
            {isMQ1 && galleryMobile}
        </section>
    );
}
