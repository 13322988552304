import "./Story.scss";

import React from "react";

import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";
import { TextColors } from "../../shared/interfaces/Colors";
import { useMQ1 } from "../../shared/hooks/isMobile";
import { MakeoverImageSlider } from "../MakeoverImageSlider/MakeoverImageSlider";
import { PageHeadline } from "../PageHeadline/PageHeadline";
import clsx from "clsx";
import { Cta } from "../Cta/Cta";

export type Props = {
    headline: string;
    headlineColor: TextColors;
    body: string;
    bodyColor: TextColors;
    ctaLabel: string;
    ctaURL: string;
    ctaColor: TextColors;
    pictures: Picture[];
};

/**
 * A "Story" component with a collage of four images and text
 *
 * The last image in the array is portrait. All others are landscape
 */
export function Story({
    headline,
    headlineColor,
    body,
    bodyColor,
    ctaLabel,
    ctaColor,
    ctaURL,
    pictures,
}: Props) {
    const isMQ1 = useMQ1();
    const copyClasses = clsx("copy", `-${bodyColor}`);

    const options = {
        arrows: false,
        accessibility: true,
    };

    const galleryMobile = (
        <MakeoverImageSlider
            options={options}
            pictures={pictures}
            renderControls={() => {}}
        />
    );

    const desktop = (
        <>
            <ResponsivePicture {...pictures[0]} lazy={true} />
            <ResponsivePicture {...pictures[1]} lazy={true} />
            <ResponsivePicture {...pictures[3]} lazy={true} />
            <ResponsivePicture {...pictures[2]} lazy={true} />
        </>
    );

    // @todo move the optional fields into their own constants
    return (
        <section className="story grid -almost-full">
            {headline && <PageHeadline text={headline} color={headlineColor} />}
            {body && <p className={copyClasses}>{body}</p>}
            {ctaLabel && (
                <div className="ctawrapper">
                    <Cta color={ctaColor} link={ctaURL} text={ctaLabel} />
                </div>
            )}
            {!isMQ1 && desktop}
            {isMQ1 && galleryMobile}
        </section>
    );
}
