import "./BikeFinder.scss";

import React from "react";
import { TextColors } from "../../shared/interfaces/Colors";
import { Cta } from "../Cta/Cta";

export type Props = {
    headline: string;
    ctaText: string;
    ctaLink: string;
};

export function BikeFinder({ headline, ctaText, ctaLink }: Props) {
    return (
        <section className="bike-finder grid -almost-full">
            <div className="content grid -almost-full">
                <h1 className="headline">{headline}</h1>
                <div className="ctacontainer">
                    <Cta
                        link={ctaLink}
                        text={ctaText}
                        color={TextColors.White}
                    />
                </div>
            </div>
        </section>
    );
}
