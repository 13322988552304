import "./PageHeader.scss";

import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { Menu } from "../../../../components/Menu/Menu";
import { IconLink } from "../../../../elements/IconLink/IconLink";
import { InformativePricing } from "../../../../elements/InformativePricing/InformativePricing";
import { MQS } from "../../../../shared/constants";
import { useEscape } from "../../../../shared/hooks/Keypresses";
import { MenuItem } from "../../../../shared/interfaces/MenuItem";
import { ReactComponent as MiniCartIcon } from "../../assets/navigation/minicart.svg";
import { ReactComponent as AccountIcon } from "../../assets/navigation/person.svg";
import { ReactComponent as SearchIcon } from "../../assets/navigation/search.svg";
import { ReactComponent as WorldIcon } from "../../assets/navigation/world.svg";
import { Logo } from "../../elements/Logo/Logo";
import { MiniCart } from "../../elements/MiniCart/MiniCart";
import { Props as SearchFormProps, SearchForm } from "../SearchForm/SearchForm";
import { use100vh } from "react-div-100vh";
import { useMQ1 } from "../../../../shared/hooks/isMobile";
import { useNaviHeight } from "../../../../shared/hooks/useNaviHeight";

export type Props = {
    homeUrl: string;
    navigation: {
        main: MenuItem[];
        secondary: MenuItem[];
    };
    searchForm: SearchFormProps;
    loginUrl: string;
    cartUrl: string;
    translations: {
        changeLang: string;
        cart: string;
        login: string;
        search: string;
        viewAll: string;
    };
    regionFlag: string;

    /**
     * If ecommerce is available for the current storefront
     * If not remove the login and cart links
     */
    ecommerce: boolean;
};

/**
 * Desktop navigation menu
 *
 * See https://www.w3.org/TR/wai-aria-practices/examples/disclosure/disclosure-navigation.html
 */
export function PageHeader({
    homeUrl,
    navigation,
    searchForm,
    loginUrl,
    cartUrl,
    translations,
    regionFlag,
    ecommerce = true,
}: Props) {
    const [showOverlay, setshowOverlay] = useState(false);
    const [showSearch, setshowSearch] = useState(false);
    const height = use100vh();
    const isMQ1 = useMQ1();
    const realHeight = useNaviHeight(90);

    /* @NOTE: Fix for iOS when the navigation is shown
       https://bugs.webkit.org/show_bug.cgi?id=141832

       We need to dynamically change the height due to the "bug"
       and account for the store switcher (40.5px) menu (60px * 2)

       And then only apply the styles when in MQ1
    */
    const heightStyles = isMQ1
        ? {
              style: {
                  height: height
                      ? `${realHeight}px`
                      : "calc(100vh - 90px - 90px)",
              },
          }
        : {};

    useEscape(() => {
        setshowSearch(false);
        setshowOverlay(false);
    });

    /**
     * @TODO: Rework this once we rewrite the language switch
     * and use a proper <dialog/> element
     *
     * @TODO: Move into a hook
     *
     * Toggle the class to show/hide the language switcher module within
     * Magento 2
     */
    useEffect(() => {
        const langSwitchClose = document.querySelector(
            ".store-switcher__close"
        );

        langSwitchClose?.addEventListener("click", () => {
            toggleLangSwitch();
        });
    }, []);

    const navClasses = clsx("navigation", {
        _hidden: !showOverlay,
    });

    /**
     * Toggle the hidden class so we show the navigation
     * and check if we're on mobile or not so the navigation
     * "sticks" and the user only scrolls within the navigation
     */
    const toggleNavigation = () => {
        // @TODO: Get resizing working in Jest
        /* istanbul ignore next */
        if (MQS.matches) {
            const body = document.querySelector("html");
            body?.classList.toggle("-bmc-no-scroll");
        }

        setshowOverlay(!showOverlay);
        setshowSearch(false);
    };

    /**
     * Toggle showing/hiding the search overlay
     */
    const toggleSearch = () => {
        setshowSearch(!showSearch);
        setshowOverlay(false);
    };

    const toggleLangSwitch = () => {
        const body = document.querySelector("html");
        body?.classList.toggle("store-switcher-open");
    };

    const searchClasses = clsx("search-container", {
        _hidden: !showSearch,
        "-shown": showSearch,
    });

    const langFlag = regionFlag ? (
        <img className="regionflag" src={regionFlag} alt="current region" />
    ) : (
        <WorldIcon className="world-svg" />
    );

    const miniCart = ecommerce && (
        <MiniCart
            url={cartUrl}
            label={translations.cart}
            containsItemsColor="#b0eccc"
        >
            <MiniCartIcon className="minicart-svg" />
        </MiniCart>
    );

    const login = ecommerce && (
        <IconLink url={loginUrl} label={translations.login}>
            <AccountIcon className="account-svg" />
        </IconLink>
    );

    return (
        <header className="page-header-scor scor-grid -almost-full">
            <button
                className="menubutton"
                type="button"
                aria-expanded={showOverlay}
                onClick={toggleNavigation}
                aria-controls="main-navigation"
            >
                <span id="menuTree" className="_visuallyhidden">
                    Menu
                </span>
            </button>

            <Logo url={homeUrl} />

            {miniCart}

            <nav className={navClasses} id="main-navigation" {...heightStyles}>
                {/* @NOTE: Still render the header if the menu isn't yet created
                within Magento 2 */}
                {navigation && navigation.main && (
                    <Menu
                        main={navigation.main}
                        secondary={navigation.secondary}
                        translations={{ viewAll: translations.viewAll }}
                        menuID="menuTree"
                    />
                )}
            </nav>

            <div className={searchClasses} {...heightStyles}>
                <SearchForm {...searchForm} />
            </div>

            <div className="icons">
                <button
                    type="button"
                    onClick={toggleLangSwitch}
                    className="header__language-switcher store-switcher-toggle"
                >
                    <span className="_visuallyhidden">Change language</span>
                    {langFlag}
                </button>

                <InformativePricing />

                <button
                    className="search"
                    type="button"
                    aria-expanded={showSearch}
                    onClick={toggleSearch}
                    aria-controls="site-search-form"
                >
                    <span className="_visuallyhidden">
                        {translations.search}
                    </span>
                    <SearchIcon className="search-svg" />
                </button>

                {login}

                {miniCart}
            </div>
        </header>
    );
}
