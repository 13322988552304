import "./HighlightItem.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../ResponsivePicture/ResponsivePicture";
import clsx from "clsx";
import { TextColors } from "../../../shared/interfaces/Colors";
import { Cta, Props as CTA } from "../../Cta/Cta";
import { Icon } from "../../../features/adicta/shared/interfaces/Icon";

export type BMCHighlightItem = {
    headline: string;
    copy: string;
    cta: CTA;
    picture: Picture;
    icon: Icon;
    color: TextColors;
};
export type Props = {
    slide: BMCHighlightItem;
};

export function HighlightItem({ slide }: Props) {
    const { headline, copy, cta, picture, icon, color } = slide;
    const headlineClasses = clsx("headline", `-${color}`);
    const bodyTextClasses = clsx("copy", `-${color}`);

    return (
        <div className="bmc-highlight-item">
            <ResponsivePicture {...picture} />
            <div className="content-wrapper">
                {icon && (
                    <img className="icon" src={icon.icon} alt={icon.alt} />
                )}
                <p className={headlineClasses}>{headline}</p>
                <p className={bodyTextClasses}>{copy}</p>
                <Cta text={cta.text} color={color} link={cta.link} />
            </div>
        </div>
    );
}
