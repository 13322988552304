import "./MiniCart.scss";

import React, { ReactNode } from "react";
import { IconLink } from "../IconLink/IconLink";

export type Props = {
    url: string;
    label: string;
    badge?: ReactNode;
    icon: ReactNode;
};

/**
 * The minicart component that is used in the navigation
 *
 * @TODO: Add a context for ecommerce and a hook to hide if ecommerce
 * is disabled
 */
export function MiniCart({ url, label, badge, icon }: Props) {
    return (
        <div className="makeover-minicart">
            <IconLink url={url} label={label}>
                {icon}
            </IconLink>
            {badge}
        </div>
    );
}
