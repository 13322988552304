/**
 * Third party script that enables the size finder
 * button and iFrame
 *
 * See:
 * https://docs.onlinesizing.bike/docs/introduction/getting-started/#step-3-add-the-loader-script
 */
export const loadFinder = (
    win: any,
    doc: any,
    script: string,
    key: string,
    config: any,
    src: string
) => {
    win["___OnlineSizing"] = key;
    win["___OnlineSizingConfig"] = config;
    const js = doc.createElement(script);
    js.id = key;
    js.src = src;
    js.async = true;

    const scripts = doc.getElementsByTagName(script);
    const lastScript = scripts[scripts.length - 1];
    lastScript.parentNode.insertBefore(js, lastScript);
};
