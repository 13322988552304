import "./ImageSlider.scss";

import React from "react";

// @ts-ignore
import { Splide, SplideOptions, SplideSlide } from "@splidejs/react-splide";

import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";

export type Props = {
    pictures: Picture[];
    options?: SplideOptions;
    renderControls?: any;
};

export function ImageSlider({
    pictures,
    options = {},
    renderControls = () => {},
}: Props) {
    const slides = pictures.map((picture, index) => {
        return (
            <SplideSlide key={index}>
                <ResponsivePicture {...picture} />
            </SplideSlide>
        );
    });

    return (
        <div className="image-slider grid">
            <div className="wrapper">
                <Splide options={options} renderControls={renderControls}>
                    {slides}
                </Splide>
            </div>
        </div>
    );
}
