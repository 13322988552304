import "./SupportLinks.scss";

import React from "react";
import { Link } from "../../elements/Link/Link";
import clsx from "clsx";
import { useMQ1 } from "../../shared/hooks/isMobile";
import { ListboxCollapsible } from "../ListboxCollapsible/ListboxCollapsible";

export interface SupportLink {
    active: boolean;
    id: string;
    name: string;
    url: string;
}

export type Props = {
    support: SupportLink[];
};

export function SupportLinks({ support }: Props) {
    const isMQ1 = useMQ1();

    const links = support.map((link) => {
        const classes = clsx("topic", {
            "-is-active": link.active,
        });

        return (
            <li key={link.name} className={classes}>
                <Link url={link.url} text={link.name} />
            </li>
        );
    });

    const sideBar = (
        <nav className="support-links">
            <ul className="topics">{links}</ul>
        </nav>
    );

    return isMQ1 ? <ListboxCollapsible options={support} /> : sideBar;
}
