import "./List.scss";

import React from "react";

import { ListItem } from "../ListItem/ListItem";
import {
    FooterListChildren,
    FooterListItem,
} from "../../../shared/interfaces/FooterItem";

export type Props = {
    items: FooterListItem[];
};

export function List({ items }: Props) {
    const listItems = items.map((item: FooterListItem, index: number) => (
        <ul key={index} className="list">
            <div key={index} className="headline">
                {item.headline}
            </div>

            {item.children.map(
                (children: FooterListChildren, index: number) => (
                    <ListItem
                        name={children.name}
                        url={children.link}
                        key={index}
                    />
                )
            )}
        </ul>
    ));

    return <>{listItems}</>;
}
