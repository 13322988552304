import "./HighlightCarousel.scss";

import React from "react";
// @ts-ignore
import { SplideOptions } from "@splidejs/react-splide";
import {
    HighlightItem,
    HighlightItemProps,
} from "./HighlightItem/HighlightItem";
import { ContentSlider } from "../../../../components/ContentSlider/ContentSlider";

export type Props = {
    headline: string;
    subheadline: string;
    sliderItems: HighlightItemProps[];
};

export function HighlightCarousel({
    headline,
    subheadline,
    sliderItems,
}: Props) {
    const itemsMarkup = sliderItems?.map((item, index) => (
        <HighlightItem sliderItem={item} key={index} />
    ));

    const options: SplideOptions = {
        arrows: true,
        perPage: 1,
        perMove: 1,
        pagination: false,
        gap: 32,
        fixedWidth: "970px", // from Design
        type: "loop",
        start: 1,
        breakpoints: {
            768: {
                gap: 16,
                fixedWidth: "300px",
            },
        },
    };

    const sliderMarkup = (
        <ContentSlider options={options}>{itemsMarkup}</ContentSlider>
    );

    return (
        <section className="highlight-carousel grid">
            {subheadline && <h2 className="subheadline">{subheadline}</h2>}
            {headline && <h1 className="headline">{headline}</h1>}
            {sliderMarkup}
        </section>
    );
}
