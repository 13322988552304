import "./TeaserProductStory.scss";

import React, { useEffect, useRef, useState } from "react";
import { useInViewport } from "ahooks";
import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";
import { Cta } from "../Cta/Cta";
import { TextColors } from "../../shared/interfaces/Colors";
import clsx from "clsx";

export type Props = {
    headline: string;
    ctaLabel: string;
    ctaUrl: string;
    copy: string;
    frameColor: string;
    colorHeadline: TextColors;
    color: TextColors;
    ctaColor: TextColors;
    productImage: Picture[];
    pictures: Picture[];
};

export function TeaserProductStory({
    headline,
    ctaLabel,
    ctaUrl,
    copy,
    frameColor,
    colorHeadline,
    color,
    ctaColor,
    productImage,
    pictures,
}: Props) {
    const headlineClasses = clsx("frame-headline", `-${colorHeadline}`);
    const copyClasses = clsx("copy", `-${color}`);
    const reference = useRef<HTMLElement>(null);
    const [inViewport] = useInViewport(reference, { threshold: 0.75 });
    const [canAnimationStart, setCanAnimationStart] = useState(false);
    const teaserClasses = clsx("teaser-product-story grid -almost-full", {
        "-in-view": canAnimationStart,
    });

    // Set the Animation to be started only once
    useEffect(() => {
        inViewport && setCanAnimationStart(true);
    }, [inViewport]);

    return (
        <section ref={reference} className={teaserClasses}>
            <div
                className="frame-container grid -almost-full"
                style={{ backgroundColor: frameColor }}
            >
                <h1 className={headlineClasses}>{headline}</h1>
                <ResponsivePicture {...productImage[0]} />
            </div>
            <ResponsivePicture {...pictures[0]} />
            <div className="copy-container">
                <p className={copyClasses}>{copy}</p>
                <Cta link={ctaUrl} text={ctaLabel} color={ctaColor} />
            </div>
            <ResponsivePicture {...pictures[1]} />
            <ResponsivePicture {...pictures[2]} />
        </section>
    );
}
