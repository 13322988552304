import "./ResponsivePicture.scss";

import React from "react";

export type PictureImage = {
    query: "mq1" | "mq2";
    url: string;
};

export type Picture = {
    images: PictureImage[];
    alt: string;
    lazy?: boolean;
};

export const mqs = {
    mq1: "(max-width: 767px)",
    mq2: "(min-width: 768px)",
};

export function ResponsivePicture({ images, alt, lazy = false }: Picture) {
    const sources = images.map(({ query, url }: PictureImage) => (
        <source key={mqs[query]} srcSet={url} media={mqs[query]} />
    ));

    const fallback = images.find((image) => image.query === "mq2");

    return (
        <picture className="responsive-picture">
            {sources}
            <img
                className="fallback"
                src={fallback?.url}
                alt={alt}
                {...(lazy && { loading: "lazy" })}
            />
        </picture>
    );
}
