import "./ListItem.scss";

import React from "react";

import { Link } from "../../../../../elements/Link/Link";

export type Props = {
    name: string;
    url: string;
};

export function ListItem({ name, url }: Props) {
    return (
        <li className="list-item">
            <Link text={name} url={url} />
        </li>
    );
}
