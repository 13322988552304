import "./StickyLinkContainer.scss";

import React from "react";
import {
    StickyLinkWithIcon,
    Brand,
    StickyLink,
} from "../../elements/StickyLink/StickyLink";
import { useMQ1 } from "../../shared/hooks/isMobile";
import { StickyLinkBar } from "../../elements/StickyLinkBar/StickyLinkBar";

export type Props = {
    links: StickyLinkWithIcon[];
    brand: Brand;
};

export function StickyLinkContainer({ links, brand }: Props) {
    const isMQ1 = useMQ1();

    // If the links haven't been configured then don't render the component
    if (!links.length) {
        return null;
    }

    const supportLinks = links
        .map((link) => ({ ...link, brand }))
        .map((brandedLink) => (
            <StickyLink key={brandedLink.text} {...brandedLink} />
        ));

    return (
        <div className="sticky-link-container grid -almost-full">
            {isMQ1 ? (
                <StickyLinkBar links={links} brand={brand} />
            ) : (
                <div className="links">{supportLinks}</div>
            )}
        </div>
    );
}
