import "./ProductTeaser.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";
import { Cta } from "../Cta/Cta";
import { TextColors } from "../../shared/interfaces/Colors";
import clsx from "clsx";

export type Props = {
    headline: string;
    ctaLabel: string;
    ctaUrl: string;
    frameColor: string;
    headlineColor: TextColors;
    ctaColor: TextColors;
    productImage: Picture;
    picture: Picture;
};

export function ProductTeaser({
    headline,
    ctaLabel,
    ctaUrl,
    frameColor,
    headlineColor,
    ctaColor,
    productImage,
    picture,
}: Props) {
    const headlineClasses = clsx(
        "product-teaser-headline",
        `-${headlineColor}`
    );

    return (
        <section
            className="product-teaser grid"
            style={{ backgroundColor: frameColor }}
        >
            <div className="content">
                <div className="cta-container">
                    <h1 className={headlineClasses}>{headline}</h1>
                    <Cta link={ctaUrl} text={ctaLabel} color={ctaColor} />
                </div>
                <ResponsivePicture {...productImage} />
            </div>
            <ResponsivePicture {...picture} />
            <Cta link={ctaUrl} text={ctaLabel} color={ctaColor} />
        </section>
    );
}
