import "./HomeSlider2.scss";

import clsx from "clsx";
import React from "react";

import { TextColors } from "../../shared/interfaces/Colors";
import { Cta } from "../Cta/Cta";
import { PageHeadline } from "../PageHeadline/PageHeadline";
import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";

export type Props = {
    picture: Picture;
    headline: string;
    subheadline: string;
    ctaText: string;
    ctaLink: string;
    ctaColor: TextColors;
    headlineColor: TextColors;
    subheadlineColor: TextColors;
    backgroundColor: string;
};

export function HomeSlider2({
    picture,
    headline,
    subheadline,
    ctaText,
    ctaLink,
    ctaColor,
    headlineColor,
    subheadlineColor,
    backgroundColor,
}: Props) {
    const mottoClasses = clsx("motto", `-${subheadlineColor}`);

    return (
        <section className="home-slider2 grid -almost-full">
            <div
                className="content"
                style={{
                    backgroundColor,
                }}
            >
                <PageHeadline text={headline} color={headlineColor} />
                <p className={mottoClasses}>{subheadline}</p>
                <div className="cta-wrapper">
                    <Cta text={ctaText} link={ctaLink} color={ctaColor} />
                </div>
            </div>
            <ResponsivePicture images={picture.images} alt={picture.alt} />
        </section>
    );
}
