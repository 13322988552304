import "./TreeItem.scss";

import React from "react";

import { MenuItem } from "../../../../shared/interfaces/MenuItem";

export type Props = {
    item: MenuItem;
};

/**
 * A single node of a tree.
 *
 * Represents a link within the main navigation.
 *
 * Currently only used in MQ1.
 */
export function TreeItem({ item }: Props) {
    return (
        <li className="tree-item" role="none">
            <a role="treeitem" href={item.url}>
                {item.name}
            </a>
        </li>
    );
}
