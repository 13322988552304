import "./IconLink.scss";

import React, { PropsWithChildren } from "react";
import clsx from "clsx";

export type Props = {
    url: string;
    label: string;
    showLabel?: boolean;
};

export function IconLink({
    url,
    label,
    showLabel = false,
    children,
}: PropsWithChildren<Props>) {
    const classes = clsx("bmc-icon-link", {
        "-show-label": showLabel,
    });

    return (
        <a href={url} className={classes}>
            {children}
            <span className="label">{label}</span>
        </a>
    );
}
