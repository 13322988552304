import "./SpecialFeature.scss";

import React from "react";

import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { ColorText } from "../../shared/interfaces/ColorText";
import clsx from "clsx";
import { Cta, Props as CTA } from "../../elements/Cta/Cta";

export type Props = {
    backgroundPicture: Picture;
    foregroundPicture: Picture;
    showFilter: boolean;
    blackWhiteFilter: boolean;
    headline: ColorText;
    copy: ColorText;
    filterPicture: string;
    cta: CTA;
};

export function SpecialFeature({
    backgroundPicture,
    showFilter,
    blackWhiteFilter,
    headline,
    copy,
    foregroundPicture,
    filterPicture,
    cta,
}: Props) {
    const copyClasses = clsx("copy", `-${copy.color}`);
    const headlineClasses = clsx("headline", `-${headline.color}`);
    const specialFeatureClasses = clsx("special-feature", {
        "-blackandwhite": blackWhiteFilter,
    });

    return (
        <section className={specialFeatureClasses}>
            <div className="foreground">
                <div className="content">
                    <h1 className={headlineClasses}>{headline.text}</h1>
                    <p className={copyClasses}>{copy.text}</p>
                    <Cta {...cta} />
                </div>
                <ResponsivePicture {...foregroundPicture} />
            </div>
            <div className="background">
                {showFilter && (
                    <img className="filter" src={filterPicture} alt="filter" />
                )}
                <ResponsivePicture {...backgroundPicture} />
            </div>
        </section>
    );
}
