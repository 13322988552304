import { MenuItem } from "./../../shared/interfaces/MenuItem";

/**
 * Add each parent element to its children as a leaf node,
 * otherwise the user isn't ever able to navigate to the parents
 * since they're displayed as a clickable headline to open/close
 * the children.
 */
export function dupeParents(items: MenuItem[]) {
    const dupe = (items: MenuItem[]): MenuItem[] => {
        return items.map((item: MenuItem) => {
            if (item.children) {
                const { url, image, name, id } = item;
                const parentLeaf = { url, image, name, id };
                return {
                    ...item,
                    children: dupe([parentLeaf, ...item.children]),
                };
            }

            return item;
        });
    };

    return dupe(items);
}

/**
 * Add each parent node to its children as a leaf, however,
 * instead of using the name of the parent as its label we use the
 * passed in string (e.g. "View All") and move it to the end of the
 * array.
 */
export function addViewAll(items: MenuItem[], label: string) {
    const dupe = (items: MenuItem[]): MenuItem[] => {
        return items.map((item: MenuItem) => {
            if (item.children) {
                const { url, image, id } = item;
                const parentLeaf = { url, image, name: label, id };
                return {
                    ...item,
                    children: dupe([...item.children, parentLeaf]),
                };
            }

            return item;
        });
    };

    return dupe(items);
}
