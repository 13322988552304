import "./HighlightItem.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../../../../components/ResponsivePicture/ResponsivePicture";
import { ColorText } from "../../../shared/interfaces/ColorText";
import clsx from "clsx";
import { Icon } from "../../../shared/interfaces/Icon";

export type HighlightItemProps = {
    headline: string;
    icon: Icon;
    copy: ColorText;
    picture: Picture;
};

export type Props = {
    sliderItem: HighlightItemProps;
};

export function HighlightItem({ sliderItem }: Props) {
    const { headline, icon, copy, picture } = sliderItem;
    const copyTextClasses = clsx("copy", `-${copy.color}`);

    return (
        <div className="highlight-item">
            <ResponsivePicture {...picture} />
            <div className="content-wrapper">
                <img className="icon" src={icon.icon} alt={icon.alt} />
                <p className="headline">{headline}</p>
                <p className={copyTextClasses}>{copy.text}</p>
            </div>
        </div>
    );
}
