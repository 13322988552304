import "./SupportItem.scss";

import React from "react";

import { ReactComponent as FindAStore } from "../../../assets/svgs/find_a_store.svg";
import { ReactComponent as CustomerSupport } from "../../../assets/svgs/customer_support.svg";
import { ReactComponent as Newsletter } from "../../../assets/svgs/newsletter.svg";

export enum IconIdentifiers {
    Newsletter = "newsletter",
    CustomerSupport = "customer-support",
    FindAStore = "find-a-store",
}

export type Props = {
    identifier: IconIdentifiers;
    label: string;
    link: string;
};

export function SupportItem({ identifier, label, link }: Props) {
    // @TODO make this into an object and loop over the object
    const icon = (identifier: IconIdentifiers) => {
        switch (identifier) {
            case IconIdentifiers.FindAStore:
                return <FindAStore />;
            case IconIdentifiers.CustomerSupport:
                return <CustomerSupport />;
            case IconIdentifiers.Newsletter:
                return <Newsletter />;
        }
    };

    return (
        <div className="adicta-support-item">
            <a className="link" href={link}>
                <div className="icon">{icon(identifier)}</div>
                <p className="label">{label}</p>
            </a>
        </div>
    );
}
