import "./Logo.scss";
import { ReactComponent as BMCLogo } from "../../assets/svgs/bmc-logo.svg";

import React from "react";

export type Props = {
    url: string;
};

export function Logo({ url }: Props) {
    return (
        <div className="logo -bmc">
            <a href={url}>
                <BMCLogo className="bmcsvg" />
            </a>
        </div>
    );
}
