import "./FullWidthImage.scss";

import React from "react";

import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";

export type Props = {
    picture: Picture;
};

export function FullWidthImage({ picture }: Props) {
    return (
        <div className="adicta-full-width-image grid -almost-full">
            <ResponsivePicture images={picture.images} alt={picture.alt} />
        </div>
    );
}
