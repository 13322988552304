import "./SupportComponent.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { Cta, CTAColors } from "../../elements/Cta/Cta";

export type Props = {
    headline: string;
    headlineColor: string;
    backgroundImage: Picture;
    foregroundImage: Picture;
    ctaColor: CTAColors;
    ctaUrl: string;
    ctaLabel: string;
};

export function SupportComponent({
    headline,
    headlineColor,
    backgroundImage,
    foregroundImage,
    ctaColor,
    ctaUrl,
    ctaLabel,
}: Props) {
    return (
        <section className="scor-support-component scor-grid -almost-full">
            <ResponsivePicture {...backgroundImage} />
            <ResponsivePicture {...foregroundImage} />
            <div className="content scor-grid -almost-full">
                <h1 className="headline" style={{ color: headlineColor }}>
                    {headline}
                </h1>
                <Cta text={ctaLabel} link={ctaUrl} color={ctaColor} />
            </div>
        </section>
    );
}
