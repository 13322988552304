import "./CategoryHeader.scss";

import clsx from "clsx";
import React from "react";

import { TextColors } from "../../shared/interfaces/Colors";
import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";

export type Props = {
    heading: string;
    description: string;
    picture: Picture;
    headlineColor: TextColors;
    subheadlineColor: TextColors;
};

export function CategoryHeader({
    heading,
    description,
    picture,
    headlineColor,
    subheadlineColor,
}: Props) {
    const headlineClasses = clsx("category-headline", `-${headlineColor}`);
    const descriptionClasses = clsx(
        "category-description",
        `-${subheadlineColor}`
    );

    return (
        <header className="bmc-category-header grid -almost-full">
            <div className="content">
                <h1 className={headlineClasses}>{heading}</h1>
                <h2 className={descriptionClasses}>{description}</h2>
            </div>
            <ResponsivePicture {...picture} />
        </header>
    );
}
