import "./TextModule.scss";

import React from "react";
import clsx from "clsx";

export enum Orientation {
    Center = "center",
    Left = "left",
}

export type Props = {
    headline?: string;
    copy: string;
    orientation: Orientation;
};

export function TextModule({ headline, copy, orientation }: Props) {
    const textModuleClasses = clsx(
        "adicta-text-module grid -almost-full",
        `-${orientation}`
    );

    const headlineHtml = (
        <section className={textModuleClasses}>
            <h2 className="headline">{headline}</h2>
            <p className="content">{copy}</p>
        </section>
    );

    const noHeadlineHtml = (
        <div className={textModuleClasses}>
            <p className="content">{copy}</p>
        </div>
    );

    return headline ? headlineHtml : noHeadlineHtml;
}
