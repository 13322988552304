import "@splidejs/splide/dist/css/splide-core.min.css";
import "./MakeoverImageSlider.scss";

import React from "react";

// @ts-ignore
import { Splide, SplideSlide, SplideOptions } from "@splidejs/react-splide";
import { ReactComponent as ArrowSVG } from "../../assets/svgs/image-slider-arrow.svg";
import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";

export type Props = {
    pictures: Picture[];
    options?: SplideOptions;
    renderControls?: any;
};

// @TODO maybe export as a component for further use
const arrows = () => {
    return (
        <div className="splide__arrows">
            <button type="button" className="splide__arrow splide__arrow--prev">
                <ArrowSVG />
            </button>
            <button type="button" className="splide__arrow splide__arrow--next">
                <ArrowSVG />
            </button>
        </div>
    );
};

export function MakeoverImageSlider({
    pictures,
    options = { arrow: true, accessibility: true },
    renderControls = arrows,
}: Props) {
    const slides = pictures.map((picture, index) => {
        return (
            <SplideSlide key={index}>
                <ResponsivePicture {...picture} />
            </SplideSlide>
        );
    });

    return (
        <div className="makeover-image-slider grid -almost-full">
            <div className="wrapper">
                <Splide options={options} renderControls={renderControls}>
                    {slides}
                </Splide>
            </div>
        </div>
    );
}
