import "./HomeSlider2.scss";

import React from "react";
import clsx from "clsx";

import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { Cta, Props as CTA } from "../../elements/Cta/Cta";
import { ColorText } from "../../shared/interfaces/ColorText";
import { Orientation } from "../../../../shared/interfaces/Orientation";

export type Props = {
    headline: ColorText;
    subheadline: ColorText;
    cta: CTA;
    backgroundPicture: Picture;
    alignment: Orientation;
};

export function HomeSlider2({
    headline,
    subheadline,
    cta,
    backgroundPicture,
    alignment,
}: Props) {
    const headlineClasses = clsx("headline", `-${headline.color}`);
    const subHeadlineClasses = clsx("subheadline", `-${subheadline.color}`);
    const classes = clsx(
        "adicta-home-slider2 grid -almost-full",
        `-${alignment}`
    );

    return (
        <section className={classes}>
            <ResponsivePicture {...backgroundPicture} />
            <div className="content grid -almost-full">
                <p className={subHeadlineClasses}>{subheadline.text}</p>
                <h1 className={headlineClasses}>{headline.text}</h1>
                <div className="cta-wrapper">
                    <Cta {...cta} />
                </div>
            </div>
        </section>
    );
}
