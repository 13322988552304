import "./Teaser.scss";

import React from "react";
import {
    ResponsivePicture,
    Picture as ResponsivePictureProps,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { Cta, Props as CTA } from "../../elements/Cta/Cta";
import { ColorText } from "../../shared/interfaces/ColorText";
import clsx from "clsx";

export type Props = {
    headline: ColorText;
    cta: CTA;
    backgroundImage: ResponsivePictureProps;
    sidebarText: ColorText;
};

export function Teaser({ headline, cta, backgroundImage, sidebarText }: Props) {
    const headlineClasses = clsx("headline", `-${headline.color}`);
    const sidebarTextClasses = clsx("sidebar-text", `-${sidebarText.color}`);

    return (
        <section className="adicta-teaser grid -almost-full">
            <ResponsivePicture
                alt={backgroundImage.alt}
                images={backgroundImage.images}
                lazy={false}
            />
            <div className="content-wrapper">
                <h1 className={headlineClasses}>{headline.text}</h1>
                <div className="actions">
                    <Cta {...cta} />
                </div>
            </div>
            <p className={sidebarTextClasses}>{sidebarText.text}</p>
        </section>
    );
}
