import "./BrandTeaser.scss";

import React from "react";
import clsx from "clsx";
import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";

export type Props = {
    picture: Picture;
    headline?: string;
};

export function BrandTeaser({ picture, headline = "" }: Props) {
    const headlineClass = headline ? "-headline" : "-no-headline";
    const brandTeaserClasses = clsx("brand-teaser", headlineClass);

    return (
        <section className={brandTeaserClasses}>
            {headline && (
                <div className="brand-teaser-headline-wrapper">
                    <h1 className="brand-teaser-headline">{headline}</h1>
                </div>
            )}
            <ResponsivePicture images={picture.images} alt={picture.alt} />
        </section>
    );
}
