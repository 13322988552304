import { Bootstrapper, ContainerBuilder } from "react-habitat";

import { BikeFinder } from "./components/BikeFinder/BikeFinder";
import { BrandTeaser as MakeoverBrandTeaser } from "./components/BrandTeaser/BrandTeaser";
import { CategoryCollection } from "./components/CategoryCollection/CategoryCollection";
import { CategoryHeader } from "./components/CategoryHeader/CategoryHeader";
import { HomeSlider1 } from "./components/HomeSlider1/HomeSlider1";
import { HomeSlider2 } from "./components/HomeSlider2/HomeSlider2";
import { Newsletter } from "./components/Newsletter/Newsletter";
import { SignupForm } from "./components/Newsletter/SignupForm/SignupForm";
import { PageHeader as MakeoverPageHeader } from "./components/PageHeader/PageHeader";
import { SizeFinderButton } from "./components/SizeFinderButton/SizeFinderButton";
import { StoreSwitcher } from "./components/StoreSwitcher/StoreSwitcher";
import { Story } from "./components/Story/Story";
import { TextModule } from "./components/TextModule/TextModule";
import { Support as MakeoverSupport } from "./components/Support/Support";
import { TeaserProductStory } from "./components/TeaserProductStory/TeaserProductStory";
import { BrandTeaser as ScorBrandTeaser } from "./features/scor/components/BrandTeaser/BrandTeaser";
import { Footer as ScorFooter } from "./features/scor/components/Footer/Footer";
import { Gallery as ScorGallery } from "./features/scor/components/Gallery/Gallery";
import { HomeSlider1 as ScorHomeSlider1 } from "./features/scor/components/HomeSlider1/HomeSlider1";
import { Moodboard as ScorMoodboard } from "./features/scor/components/Moodboard/Moodboard";
import { PageHeader as ScorPageHeader } from "./features/scor/components/PageHeader/PageHeader";
import { ProductTeaser as ScorProductTeaser } from "./features/scor/components/ProductTeaser/ProductTeaser";
import { SupportComponent as ScorSupport } from "./features/scor/components/SupportComponent/SupportComponent";
import { ProductTeaser } from "./components/ProductTeaser/ProductTeaser";
import { Teaser } from "./components/Teaser/Teaser";
import { TeaserProductStory as ScorTeaserProductStory } from "./features/scor/components/TeaserProductStory/TeaserProductStory";
import { TextModule as ScorTextModule } from "./features/scor/components/TextModule/TextModule";
import { CollectionOverview as AdictaCollectionOverview } from "./features/adicta/components/CollectionOverview/CollectionOverview";
import { Teaser as AdictaTeaser } from "./features/adicta/components/Teaser/Teaser";
import { TextModule as AdictaTextModule } from "./features/adicta/components/TextModule/TextModule";
import { HighlightCarousel as BMCHighlightCarousel } from "./components/HighlightCarousel/HighlightCarousel";
import { Footer as MakeoverFooter } from "./components/Footer/Footer";
import { ProductDescription as AdictaProductDescription } from "./features/adicta/components/ProductDescription/ProductDescription";
import { CareInstructions as AdictaCareInstructions } from "./features/adicta/components/CareInstructions/CareInstructions";
import { HomeSlider1 as AdictaHomeSlider1 } from "./features/adicta/components/HomeSlider1/HomeSlider1";
import { HomeSlider2 as AdictaHomeSlider2 } from "./features/adicta/components/HomeSlider2/HomeSlider2";
import { HighlightCarousel as AdictaHighlightCarousel } from "./features/adicta/components/HighlightCarousel/HighlightCarousel";
import { CategoryProfileHeader as Adicta_CategoryProfileHeader } from "./features/adicta/components/CategoryProfileHeader/CategoryProfileHeader";
import { SpecialFeature as Adicta_SpecialFeature } from "./features/adicta/components/SpecialFeature/SpecialFeature";
import { MoodBoard as AdictaMoodBoard } from "./features/adicta/components/MoodBoard/MoodBoard";
import { IntendedUse as AdictaIntendedUse } from "./features/adicta/components/IntendedUse/IntendedUse";
import { Footer as AdictaFooter } from "./features/adicta/components/Footer/Footer";
import { FullWidthImage as AdictaFullWidthImage } from "./features/adicta/components/FullWidthImage/FullWidthImage";
import { ProductOverview as AdictaProductOverview } from "./features/adicta/components/ProductOverview/ProductOverview";
import { Support as AdictaSupport } from "./features/adicta/components/Support/Support";
import { StickyLinkContainer } from "./components/StickyLinkContainer/StickyLinkContainer";
import { SupportLinks } from "./components/SupportLinks/SupportLinks";

export class Bootstrap extends Bootstrapper {
    constructor() {
        super();

        const builder = new ContainerBuilder();

        // Layout Elements
        builder.register(StickyLinkContainer).as("Sticky_Link_Container");

        // BMC Components
        builder.register(HomeSlider1).as("HomeSlider_1");
        builder.register(HomeSlider2).as("HomeSlider_2");
        builder.register(MakeoverPageHeader).as("Makeover_PageHeader");
        builder.register(SizeFinderButton).as("SizeFinderButton");
        builder.register(TextModule).as("TextModule");
        builder.register(StoreSwitcher).as("StoreSwitcher");
        builder.register(Story).as("Story");
        builder.register(MakeoverSupport).as("Makeover_Support");
        builder.register(TeaserProductStory).as("TeaserProductStory");
        builder.register(CategoryHeader).as("CategoryHeader");
        builder.register(ProductTeaser).as("ProductTeaser");
        builder.register(BikeFinder).as("BikeFinder");
        builder.register(Teaser).as("Teaser");
        builder.register(MakeoverBrandTeaser).as("Makeover_BrandTeaser");
        builder.register(Newsletter).as("Newsletter");
        builder.register(MakeoverFooter).as("Makeover_Footer");
        builder.register(BMCHighlightCarousel).as("Makeover_Carousel");
        builder.register(CategoryCollection).as("CategoryCollection");
        builder.register(SignupForm).as("SignupForm");
        builder.register(SupportLinks).as("Support_Links");

        // Scor Components
        builder.register(ScorHomeSlider1).as("Scor_HomeSlider_1");
        builder.register(ScorProductTeaser).as("Scor_ProductTeaser");
        builder.register(ScorBrandTeaser).as("Scor_BrandTeaser");
        builder.register(ScorPageHeader).as("Scor_PageHeader");
        builder.register(ScorTextModule).as("Scor_TextModule");
        builder.register(ScorFooter).as("Scor_Footer");
        builder.register(ScorGallery).as("Scor_Gallery");
        builder.register(ScorMoodboard).as("Scor_Moodboard");
        builder.register(CategoryHeader).as("Scor_CategoryHeader");
        builder.register(ScorSupport).as("Scor_Support");
        builder.register(ScorTeaserProductStory).as("Scor_TeaserProductStory");

        // Adicta Components
        builder.register(AdictaTeaser).as("Adicta_Teaser");
        builder
            .register(AdictaCollectionOverview)
            .as("Adicta_CollectionOverview");
        builder.register(AdictaTextModule).as("Adicta_TextModule");
        builder
            .register(AdictaProductDescription)
            .as("Adicta_ProductDescription");
        builder.register(AdictaHomeSlider1).as("Adicta_HomeSlider");
        builder.register(AdictaHomeSlider2).as("Adicta_HomeSlider_2");
        builder
            .register(AdictaHighlightCarousel)
            .as("Adicta_HighlightCarousel");
        builder.register(AdictaCareInstructions).as("Adicta_CareInstructions");
        builder
            .register(Adicta_CategoryProfileHeader)
            .as("Adicta_CategoryHeader");
        builder.register(AdictaMoodBoard).as("Adicta_Moodboard");
        builder.register(Adicta_SpecialFeature).as("Adicta_SpecialFeature");
        builder.register(AdictaIntendedUse).as("Adicta_IntendedUse");
        builder.register(AdictaFooter).as("Adicta_Footer");
        builder.register(AdictaFullWidthImage).as("Adicta_FullWidthImage");
        builder.register(AdictaProductOverview).as("Adicta_ProductOverview");
        builder.register(AdictaSupport).as("Adicta_Support");

        this.setContainer(builder.build());
    }
}
