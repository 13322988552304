import "./Explore.scss";

import React from "react";

import { MenuItem } from "../../../shared/interfaces/MenuItem";

export type Props = {
    items: MenuItem[];
};

/**
 * The (currently) four links with images that are shown when the user
 * hovers over the navigation element "Explore"
 */
export function Explore({ items }: Props) {
    const listItems = items.map((item: MenuItem) => (
        <li className="listitem" key={item.id}>
            <a className="link" href={item.url}>
                <span className="title">{item.name}</span>
                <img
                    loading="lazy"
                    className="image"
                    src={item.image}
                    alt={item.name}
                />
            </a>
        </li>
    ));

    return <ul className="explore-list">{listItems}</ul>;
}
