import "./TextModule.scss";

import React from "react";

import { TextColors } from "../../shared/interfaces/Colors";
import clsx from "clsx";

export enum Orientation {
    Center = "center",
    Left = "left",
}

export type Props = {
    headline: string;
    copy: string;
    color: TextColors;
    orientation: Orientation;
};

export function TextModule({ headline, copy, color, orientation }: Props) {
    const classes = clsx("text-module", `-${color} -${orientation}`);

    return (
        <section className="grid -copy">
            <div className={classes}>
                <h1 className="headline">{headline}</h1>
                <p className="content">{copy}</p>
            </div>
        </section>
    );
}
