import "./CreateSpeed.scss";

import React from "react";

import { ReactComponent as CreateSVG } from "./create.svg";
import { ReactComponent as SpeedSVG } from "./speed.svg";

export function CreateSpeed() {
    const rows = Array.from({ length: 5 }).map((x, index) => (
        <div key={index} className="row">
            <CreateSVG />
            <SpeedSVG />
            <CreateSVG />
            <SpeedSVG />
            <CreateSVG />
            <SpeedSVG />
        </div>
    ));

    return <div className="create-speed">{rows}</div>;
}
