import "./Cta.scss";

import React from "react";
import clsx from "clsx";

export enum CTAColors {
    Black = "black",
    White = "white",
    Mint = "mint",
}

export type Props = {
    color: CTAColors;
    text: string;
    link: string;
};

export function Cta({ color, text, link }: Props) {
    const classes = clsx("scor-cta", `-${color}`);

    return (
        <a className={classes} href={link}>
            {text}
        </a>
    );
}
