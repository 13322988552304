import "./Support.scss";

import React from "react";
import { ReactComponent as PurchaseAdvice } from "../../assets/svgs/support/purchase-advice.svg";
import { ReactComponent as MechanicalSupport } from "../../assets/svgs/support/mechanical-support.svg";
import { ReactComponent as Spareparts } from "../../assets/svgs/support/spareparts.svg";
import { ReactComponent as Warranty } from "../../assets/svgs/support/warranty.svg";
import { ReactComponent as Contact } from "../../assets/svgs/support/contact.svg";

export type SupportIcon = {
    label: string;
    url: string;
    iconId: string;
};

export type Props = {
    headline: string;
    links: SupportIcon[];
};

export function Support({ headline, links }: Props) {
    const icons: any = {
        purchaseAdvice: <PurchaseAdvice />,
        mechanicalSupport: <MechanicalSupport />,
        spareparts: <Spareparts />,
        warranty: <Warranty />,
        contact: <Contact />,
    };

    const iconsCollection = links.map((supportIcon) => {
        return (
            <a
                className="icon-link"
                href={supportIcon.url}
                key={supportIcon.url}
            >
                <div className="icon-wrapper">{icons[supportIcon.iconId]}</div>
                <div className="icon-label">{supportIcon.label}</div>
            </a>
        );
    });

    return (
        <section className="support grid -almost-full">
            <div className="inner-wrapper">
                <h2 className="headline">{headline}</h2>
                <div className="icons-wrapper">{iconsCollection}</div>
            </div>
        </section>
    );
}
