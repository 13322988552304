import "./Logo.scss";

import React from "react";
import { ReactComponent as AdictaLogo } from "../../assets/svgs/adicta.svg";
import { getBaseUrl } from "../../../../shared/helpers";

export type Props = {
    url?: string;
};

export function Logo({ url = getBaseUrl() }: Props) {
    return (
        <div className="logo -adicta">
            <a href={url}>
                <AdictaLogo className="adictasvg" />
            </a>
        </div>
    );
}
