import "./ProductDescription.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";

export type Props = {
    headline: string;
    copy: string;
    backgroundPicture: Picture;
    foregroundPicture: Picture;
};

export function ProductDescription({
    headline,
    copy,
    backgroundPicture,
    foregroundPicture,
}: Props) {
    return (
        <section className="product-description grid -almost-full">
            <div className="background">
                <ResponsivePicture {...backgroundPicture} />
            </div>
            <div className="foreground">
                <ResponsivePicture {...foregroundPicture} />
            </div>
            <h1 className="headline">{headline}</h1>
            <p className="copy" dangerouslySetInnerHTML={{ __html: copy }}></p>
        </section>
    );
}
