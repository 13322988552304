import "./CareInstructions.scss";

import React, { useState } from "react";
import { Icon } from "../../shared/interfaces/Icon";
import clsx from "clsx";
import { ExpandContent } from "../../shared/interfaces/ExpandContent";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";

export type Props = {
    headline: string;
    icon: Icon;
    buttonText: string;
    expandContent: ExpandContent;
};

export function CareInstructions({
    headline,
    icon,
    buttonText,
    expandContent,
}: Props) {
    const [showContent, setShowContent] = useState(false);
    const componentClasses = clsx("care-instructions grid -almost-full", {
        "-expanded": showContent,
    });
    const headlineClasses = clsx("headline", { _hidden: showContent });
    const ctaClasses = clsx("adicta-cta -clear-white expand-content-btn", {
        _hidden: showContent,
    });
    const expandContentClasses = clsx("expanded-content", {
        _hidden: !showContent,
    });

    const expandContentCopyMarkup = expandContent.copy.length > 0 && (
        <div className="expand-content-copy">
            <ul>
                {expandContent.copy.map(
                    (item, index) => item && <li key={index}>{item}</li>
                )}
            </ul>
        </div>
    );

    const showHideContent = () => setShowContent(!showContent);

    return (
        <section className={componentClasses} id="care-instructions">
            <div className="content-wrapper grid">
                <h1 className={headlineClasses}>{headline}</h1>
                <img className="icon" src={icon.icon} alt={icon.alt} />
                <div className={ctaClasses} onClick={showHideContent}>
                    {buttonText}
                </div>
                <div className={expandContentClasses}>
                    <CloseIcon className="close" onClick={showHideContent} />
                    <h2 className="expand-content-headline">
                        {expandContent.headline}
                    </h2>
                    {expandContentCopyMarkup}
                </div>
            </div>
        </section>
    );
}
