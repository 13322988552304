import { Cart } from "./../interfaces/MageCart";
import { useState, useEffect } from "react";

/**
 * Check if the cart contains any items and return true or false
 *
 * Used to display a notification badge on the minicart if true
 */
export function useMiniCartBadge(): number {
    const [count, setCount] = useState(0);

    useEffect(() => {
        /**
         * On page load we check the local storage and set the state if
         * the cart object exists
         */
        function checkLocalStorage() {
            const mageCache = localStorage.getItem("mage-cache-storage");

            if (mageCache !== null) {
                const cache = JSON.parse(mageCache);

                if (cache.cart) {
                    setCount(cache.cart.summary_count);
                }
            }
        }

        /**
         * Update the cart's state if the event contains the cart object
         */
        function updateOnSectionsReload(event: any) {
            if ("cart" in event.detail) {
                const cart: Cart = event.detail.cart;
                setCount(cart.summary_count);
            }
        }

        document.addEventListener(
            "bmc-updated-sections",
            updateOnSectionsReload,
            false
        );

        checkLocalStorage();

        return () => {
            document.removeEventListener(
                "bmc-updated-sections",
                updateOnSectionsReload,
                false
            );
        };
    }, []);

    return count;
}
