import "./ProductTeaser.scss";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";

import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { Cta, CTAColors, Props as CTA } from "../../elements/Cta/Cta";

export type Props = {
    headline: string;
    headlineColor: string;
    ctas: CTA[];
    ctaColor: CTAColors;
    pictures: Picture[];
};

export function ProductTeaser({
    headline,
    ctas,
    pictures,
    headlineColor,
    ctaColor,
}: Props) {
    const ctaList = ctas.map((link, i) => (
        <li className="item" key={i}>
            <Cta {...link} color={ctaColor} />
        </li>
    ));
    const slides = pictures.map((picture, i) => (
        <SplideSlide key={i}>
            <ResponsivePicture {...picture} />
        </SplideSlide>
    ));
    const options = {
        type: "fade",
        perPage: 1,
        gap: 0,
        padding: 0,
        autoplay: true,
        arrows: false,
        slideFocus: false,
        pagination: false,
        lazyLoad: true,
        rewind: true,
        width: "100%",
        speed: 200,
        throttle: 25,
    };

    return (
        <section className="scor-product-teaser">
            <div className="content scor-grid -almost-full">
                <h2
                    className="productheadline"
                    style={{
                        color: headlineColor,
                    }}
                >
                    {headline}
                </h2>
                <ul className="ctalist">{ctaList}</ul>
                <div className="splide-container">
                    <Splide options={options}>{slides}</Splide>
                </div>
            </div>
        </section>
    );
}
