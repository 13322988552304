import "./CategoryCollection.scss";

import React from "react";
import { LeftRight } from "../../shared/interfaces/Orientation";
import { TextColors } from "../../shared/interfaces/Colors";
import clsx from "clsx";
import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";
import { ColorText } from "../../shared/interfaces/ColorText";
import { Cta } from "../Cta/Cta";
import { ContentSlider } from "../ContentSlider/ContentSlider";
import { CategorySlide } from "./CategorySlide/CategorySlide";

export type CategoryProduct = {
    productPicture: Picture;
    url: string;
};

export type Props = {
    headline: ColorText;
    orientation: LeftRight;
    backgroundColor: string;
    ctaLabel: string;
    ctaColor: TextColors;
    ctaUrl: string;
    categoryProducts: CategoryProduct[];
    categoryPicture: Picture;
};

export function CategoryCollection({
    headline,
    orientation,
    backgroundColor,
    ctaLabel,
    ctaColor,
    ctaUrl,
    categoryProducts,
    categoryPicture,
}: Props) {
    const classes = clsx(
        "makeover-category-collection grid -almost-full",
        `-${orientation}`
    );
    const headlineClasses = clsx("headline", `-${headline.color}`);
    const slides = categoryProducts.map((product, index) => (
        <CategorySlide key={index} product={product} />
    ));

    // @NOTE: In design there are arrows, but BMC decided not to have them
    // due to the size being too small for the product image
    const options = {
        arrows: false,
        interval: 3000,
        autoplay: true,
        rewind: true,
    };
    const CTA = (
        <div className="ctawrapper">
            <Cta text={ctaLabel} link={ctaUrl} color={ctaColor} />
        </div>
    );

    return (
        <section className={classes}>
            <div
                className="container grid -almost-full"
                style={{
                    backgroundColor,
                }}
            >
                <h1 className={headlineClasses}>{headline.text}</h1>
                {CTA}
                <ContentSlider options={options} children={slides} />
            </div>
            <ResponsivePicture {...categoryPicture} />
            {CTA}
        </section>
    );
}
