import "./StickyLink.scss";

import React from "react";

import { ReactComponent as ChatBubble } from "../../assets/svgs/chat-bubble.svg";
import { ReactComponent as Pin } from "../../assets/svgs/pin.svg";
import { IconLink } from "../IconLink/IconLink";
import clsx from "clsx";

export const StickyLinkIcon = {
    chat: <ChatBubble className="chatsvg" />,
    pin: <Pin className="pinsvg" />,
};

export enum Icon {
    chat = "chat",
    pin = "pin",
}

export enum Brand {
    makeover = "makeover",
    adicta = "adicta",
    scor = "scor",
}

export interface StickyLinkWithIcon {
    icon: Icon;
    url: string;
    text: string;
}

export type Props = StickyLinkWithIcon & {
    brand: Brand;
};

export function StickyLink({ icon, url, text, brand }: Props) {
    const classes = clsx("bmc-sticky-link", `-${brand}`);

    return (
        <div className={classes}>
            <IconLink url={url} label={text} showLabel={true}>
                {StickyLinkIcon[icon]}
            </IconLink>
        </div>
    );
}
