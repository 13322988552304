import "./Footer.scss";
import { ReactComponent as ScorFooterLogo } from "../../assets/scor-footer-logo.svg";

import React from "react";

export type Props = {};

export function Footer() {
    return (
        <footer className="footer scor-grid">
            <div className="footer-col -newsletter">
                <div className="footer-newsletter-headline">
                    wanna stay tuned?
                </div>
                <div className="footer-newsletter-content">
                    Signup for email updates on the latest Scor collections,
                    campaigns and videos.
                </div>
            </div>
            <ul className="footer-col -list">
                <li className="footer-col-item -headline">support</li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
            </ul>
            <ul className="footer-col -list">
                <li className="footer-col-item -headline">shop</li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
            </ul>
            <ul className="footer-col -list">
                <li className="footer-col-item -headline">scor</li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
            </ul>
            <ul className="footer-col -list -bottom-row">
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
                <li className="footer-col-item -link">
                    <a
                        className="footer-col-item-link"
                        href="https://link-to-somewhere.de"
                    >
                        Purchase Advise
                    </a>
                </li>
            </ul>
            <ScorFooterLogo className="footer-col -scor-footer-logo" />
        </footer>
    );
}
