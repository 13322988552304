import "./AutoExternalLink.scss";

import React from "react";

import { isExternalLink } from "../../shared/helpers";
import { Link } from "../Link/Link";

export type Props = {
    url: string;
    text: string;
};

/**
 * A link which automatically opens external links (URLs that aren't in its domain) in a new tab
 */
export function AutoExternalLink({ url, text }: Props) {
    const props = {
        url,
        text,
        isExternal: isExternalLink(url),
    };

    return <Link {...props} />;
}
