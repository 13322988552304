import "./Support.scss";

import React from "react";

import { SupportItem, Props as Icon } from "./SupportItem/SupportItem";
import {
    ResponsivePicture,
    Picture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";

export type Props = {
    headline: string;
    backgroundPicture: Picture;
    icons: Icon[];
};

export function Support({ headline, backgroundPicture, icons }: Props) {
    const iconMarkup = icons.map((icon, index) => {
        return (
            <SupportItem
                key={index}
                identifier={icon.identifier}
                label={icon.label}
                link={icon.link}
            />
        );
    });

    return (
        <div className="adicta-support grid">
            <ResponsivePicture {...backgroundPicture} />
            <div className="filter" />
            <h1 className="headline">{headline}</h1>
            <div className="items">{iconMarkup}</div>
        </div>
    );
}
