import { useEffect } from "react";

/**
 * When the user clicks the ESC key we want to run a specific function
 * via the callback
 *
 * @param onEscape - Callback function to be called when Escape is pressed
 */
export function useEscape(onEscape: () => void) {
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === "Esc" || event.key === "Escape") {
                onEscape();
            }
        };

        window.addEventListener("keydown", handleEsc);

        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [onEscape]);
}
