import "./ScorImageSlider.scss";

import React from "react";
import { ImageSlider } from "../../../../components/ImageSlider/ImageSlider";
import { Picture } from "../../../../components/ResponsivePicture/ResponsivePicture";

// @ts-ignore
import { SplideOptions } from "@splidejs/react-splide";

export enum Types {
    Top = "top",
    Bottom = "bottom",
    Left = "left",
}

export type Props = {
    pictures: Picture[];
    headline?: string;
    type?: Types;
};

export function ScorImageSlider({
    pictures,
    headline,
    type = Types.Top,
}: Props) {
    const options: SplideOptions = {
        arrows: false,
        accessibility: true,
    };

    return (
        <div className={`scor-image-slider -${type}`}>
            <ImageSlider pictures={pictures} options={options} />
        </div>
    );
}
