import "./SearchForm.scss";

import React, { useState } from "react";

import { ReactComponent as CloseButton } from "../../assets/navigation/close.svg";

export type Translation = {
    placeholder: string;
    reset: string;
    submit: string;
};

export type Hint = {
    title: string;
    results: string;
};

export type Props = {
    searchURL: string;
    translations: Translation;
};

const initialState = {
    search: "",
    hints: [],
};

export function SearchForm({ translations, searchURL }: Props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [form, setForm] = useState(initialState);
    const onFormChange = (nextValue: any) => setForm(nextValue);

    return (
        <div className="makeover-search-form grid -almost-full">
            <form
                className="form"
                onChange={onFormChange}
                role="search"
                method="get"
                action={searchURL}
                id="site-search-form"
            >
                <label htmlFor="site-search">
                    <span className="_visuallyhidden">
                        {translations.placeholder}
                    </span>
                </label>
                <input
                    minLength={3}
                    className="searchinput"
                    placeholder={translations.placeholder}
                    type="search"
                    name="q"
                    id="site-search"
                    spellCheck="false"
                />
                <button className="searchsubmit">{translations.submit}</button>
                <button className="searchreset" type="reset">
                    <span className="_visuallyhidden">
                        {translations.reset}
                    </span>
                    <CloseButton />
                </button>
            </form>
        </div>
    );
}
