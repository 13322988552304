import { MQS } from "./../constants";
import { useState, useEffect } from "react";

/**
 * Check if the component is in the mobile MQ or not
 *
 * Used to assist components in determining which component to display per MQ
 */
export function useMQ1() {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        if (MQS.matches !== matches) {
            setMatches(MQS.matches);
        }

        const handleChange = () => {
            setMatches(MQS.matches);
        };

        MQS.addEventListener("change", handleChange);

        return () => {
            MQS.removeEventListener("change", handleChange);
        };
    }, [matches]);

    return matches;
}
