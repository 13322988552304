import "./CategoryItem.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../../../../components/ResponsivePicture/ResponsivePicture";
import { UnderlinedLink } from "../../../elements/UnderlinedLink/UnderlinedLink";

export type Collection = {
    headline: string;
    subheadline: string;
    copy: string;
    picture: Picture;
    linkText: string;
    linkUrl: string;
};

export type Props = {
    collection: Collection;
};

export function CategoryItem({ collection }: Props) {
    const { headline, subheadline, copy, picture, linkText, linkUrl } =
        collection;

    return (
        <div className="category-item">
            <ResponsivePicture {...picture} />
            <p className="subheadline">{subheadline}</p>
            <p className="headline">{headline}</p>
            <p className="content">{copy}</p>
            <div className="actions">
                <UnderlinedLink url={linkUrl} text={linkText} />
            </div>
        </div>
    );
}
