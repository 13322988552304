import "./ProgressBar.scss";

import React, { useEffect, useState } from "react";

export type Props = {
    fill: number;
};

export function ProgressBar({ fill }: Props) {
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        setInterval(() => setCompleted(fill), 50);
    });

    return (
        <div className="progress-bar">
            <div className="joist" />
            <div
                className="fill"
                style={{
                    width: completed + "%",
                }}
            ></div>
        </div>
    );
}
