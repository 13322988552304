import "./HighlightCarousel.scss";

import React from "react";
import { BMCHighlightItem, HighlightItem } from "./HighlightItem/HighlightItem";
// @ts-ignore
import { SplideOptions } from "@splidejs/react-splide";

import { ContentSlider } from "../ContentSlider/ContentSlider";
import { useMQ1 } from "../../shared/hooks/isMobile";
import { mqsWidth } from "../../shared/constants";

export type Props = {
    carouselSlides: BMCHighlightItem[];
};

export function HighlightCarousel({ carouselSlides }: Props) {
    const items = carouselSlides?.map((slide, index) => (
        <HighlightItem slide={slide} key={index} />
    ));

    const isMQ1 = useMQ1();

    const options: SplideOptions = {
        breakpoints: {},
    };

    const sliderBreakpoint = mqsWidth != null && parseInt(mqsWidth, 10);

    options.breakpoints[sliderBreakpoint] = {
        arrows: true,
        perPage: 1,
        perMove: 1,
        pagination: false,
        gap: 18,
        fixedWidth: "230px",
        type: "loop",
        start: 1,
    };

    const carouselMarkup = (
        <ContentSlider options={options}>{items}</ContentSlider>
    );

    return (
        <div className="bmc-highlight-carousel grid -almost-full">
            {!isMQ1 && items}
            {isMQ1 && carouselMarkup}
        </div>
    );
}
