import { useMQ1 } from "../../shared/hooks/isMobile";
import { MenuItem } from "../../shared/interfaces/MenuItem";
import { Desktop } from "./Desktop/Desktop";
import { Mobile } from "./Mobile/Mobile";
import { addViewAll } from "./utils";

export type Props = {
    main: MenuItem[];
    secondary: MenuItem[];
    menuID: string;
    translations: {
        viewAll: string;
    };
};

/**
 * The desktop display of the the navigation in a 3 column display
 * which is shown/hidden as the user hovers over each entry.
 *
 * Each entry has an image which is also switched out as the user
 * hovers over the menu items.
 *
 */
export function Menu({ main, secondary, menuID, translations }: Props) {
    const isMQ1 = useMQ1();

    if (isMQ1) {
        const dupedParents = addViewAll(main, translations.viewAll);
        return (
            <Mobile
                main={dupedParents}
                labelBy={menuID}
                secondary={secondary}
            />
        );
    } else {
        return <Desktop main={main} secondary={secondary} />;
    }
}
