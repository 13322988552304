import "./StoreSwitcher.scss";

import React from "react";
import clsx from "clsx";
export interface Store {
    code: string;
    label: string;
    link: string;
}

export type Props = {
    stores: Store[];
    activeCode: string;
};

export function StoreSwitcher({ stores, activeCode }: Props) {
    const storeList = stores.map((store) => {
        const itemClasses = clsx("storeitem", {
            "-active": activeCode === store.code,
        });

        return (
            <li className={itemClasses} key={store.code}>
                <a href={store.link}>{store.label}</a>
            </li>
        );
    });

    return (
        <nav className="makeover-store-switcher grid -almost-full">
            <ul className="storelist">{storeList}</ul>
        </nav>
    );
}
