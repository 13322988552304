import "./Desktop.scss";

import React, { useState, useEffect, useRef } from "react";
import { MenuItem } from "../../../shared/interfaces/MenuItem";
import { List } from "../Desktop/List/List";
import { Explore } from "../Explore/Explore";
import clsx from "clsx";
import { exploreID } from "../constants";
import { useInterval } from "ahooks";

export type Props = {
    main: MenuItem[];
    secondary: MenuItem[];
};

/**
 * The desktop display of the the navigation in a 3 column display
 * which is shown/hidden as the user hovers over each entry.
 *
 * Each entry has an image which is also switched out as the user
 * hovers over the menu items.
 *
 */
export function Desktop({ main, secondary }: Props) {
    let levels: any = [];
    const hiddenClass = "hidden";
    const menu = useRef<HTMLDivElement>(null);
    const initialState = {
        depth: 0,
        name: "",
        image: "",
    };
    const [selectedItem, setSelectedItem] = useState(initialState);
    const [cachedItem, setCachedItem] = useState(initialState);
    const [isPaused, setPaused] = useState(true);
    const [timer, setTimer] = useState(0);

    useInterval(
        () => {
            if (timer < 250) {
                setTimer(timer + 10);
            } else {
                setPaused(true);
                setSelectedItem({ ...cachedItem });
            }
        },
        isPaused ? undefined : 10
    );

    const seondaryWithParent = secondary.map((item) => ({
        ...item,
        parent: "secondary",
    }));

    const exploreItems = () => {
        const exploreItem = secondary.find((item) => item.id === exploreID);

        // @TODO: This needs to be based on something other than the name
        const classes = clsx("explorecontainer", {
            [hiddenClass]:
                exploreItem && !(selectedItem.name === exploreItem.name),
        });

        if (!exploreItem?.children) {
            return null;
        }

        return (
            <div className={classes}>
                <Explore items={exploreItem.children} />
            </div>
        );
    };

    const onMouseLeave = (event: React.MouseEvent<HTMLLIElement>) => {
        setTimer(0);
        setPaused(true);
    };

    /**
     * Update the state when the user moves to a new menu item
     *
     */
    const onMouseEnter = (event: React.MouseEvent<HTMLLIElement>) => {
        const current = event.currentTarget;

        setTimer(0);
        setPaused(false);

        // Start timer
        // After the timer reaches 200ms update the state
        // Then cancel the timer
        // Reset the timer once mouseOut?

        setCachedItem({
            name: current.dataset.current!,
            depth: parseInt(
                current.parentElement?.parentElement?.dataset.depth!,
                10
            ),
            image: current.dataset.image!,
        });
    };

    // @TODO: Extract into helper function and improve
    const terse = (data: MenuItem[], level: number, parent = "root") => {
        if (!levels[level]) {
            levels[level] = [];
        }

        const currentItems = data.map((item: MenuItem) => {
            if (item.children) {
                terse(item.children, level + 1, item.name);
            }

            const { name, url, image } = item;
            return {
                name,
                url,
                image,
                parent,
            };
        });

        levels[level].push(...currentItems);
    };

    terse(main, 0);

    const listItems = (menuItems: any, hidden = false) => {
        const chunkedByParent = menuItems.reduce((accum: any, item: any) => {
            accum[item.parent] = [...(accum[item.parent] || []), item];

            return accum;
        }, {});

        return Object.keys(chunkedByParent).map((key) => {
            const items = chunkedByParent[key];

            return (
                <List
                    key={key}
                    items={items}
                    hidden={hidden}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    parent={key}
                />
            );
        });
    };

    const categories = levels.slice(0, 3).map((level: any[], index: number) => {
        const hidden = index > 0;
        const submenu = index === 0 ? listItems(seondaryWithParent) : null;
        return (
            <div key={index} data-depth={index} className="level">
                {listItems(level, hidden)}
                {submenu}
            </div>
        );
    });

    const previewClasses = clsx("preview", {
        "-product": selectedItem.depth === 2,
    });

    /**
     * Handle a change when the user hovers over a new list item
     *
     * @TODO: Refactor
     * @TODO: Image needs to be delayed as well
     */
    useEffect(() => {
        const { name, depth } = selectedItem;

        if (name && menu.current) {
            const siblings = Array.from(
                menu.current.querySelectorAll(".level")
            );

            siblings.forEach((sibling, index, arr) => {
                if (depth >= arr.length - 1) {
                    return;
                }

                if (depth === index) {
                    return;
                }

                // When the user hovers over a different entry
                // show/hide the proper sublist of menu items
                if (index > depth && sibling.children.length) {
                    const children = Array.from(sibling.children);

                    children.forEach((child: any) => {
                        if (child.dataset.parent === name) {
                            child.classList.remove(hiddenClass);
                        } else {
                            child.classList.add(hiddenClass);
                        }
                    });
                }

                // Since :has isn't yet supported we check to see if any children are
                // visible and if so toggle the class to display the border
                if (sibling.children && index !== 0) {
                    const children = Array.from(sibling.children);
                    if (
                        children.every((child) =>
                            child.classList.contains(hiddenClass)
                        )
                    ) {
                        sibling.classList.remove("-with-border");
                    } else {
                        sibling.classList.add("-with-border");
                    }
                }
            });
        }
    }, [selectedItem]);

    return (
        <div className="menu-list grid" ref={menu}>
            {/* @TODO: Get image alt text */}
            {categories}
            {exploreItems()}
            <div className={previewClasses}>
                {/* @SEE: https://www.w3.org/WAI/tutorials/images/decorative/#image-used-as-part-of-page-design */}
                {selectedItem.image && <img src={selectedItem.image} alt="" />}
            </div>
        </div>
    );
}
