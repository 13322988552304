import "./ListboxCollapsible.scss";

import React, { useEffect, useRef, useState } from "react";
import { SupportLink } from "../SupportLinks/SupportLinks";
import { Link } from "../../elements/Link/Link";
import { ReactComponent as Arrow } from "../../assets/svgs/arrow.svg";
import clsx from "clsx";
import { useClickAway } from "ahooks";

export type Props = {
    options: SupportLink[];
};

/**
 * Created for the FAQ or Support page this "dropdown" is used to navigate between
 * the links passed in to the component.
 *
 * This looks and functions almost like a `<select/>` but has otherwise no similiarities.
 *
 * @SEE https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
 */
export function ListboxCollapsible({ options }: Props) {
    const [activeLink, setActiveLink] = useState({} as SupportLink);
    const [showList, setShowList] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const arrowClasses = clsx("arrowsvg", {
        "-is-open": showList,
    });

    useClickAway(() => {
        setShowList(false);
    }, ref);

    useEffect(() => {
        let active = options.find((option) => option.active);

        if (!active) {
            active = options[0];
        }

        setActiveLink(active);
    }, [options]);

    const links = options

        // We don't want to show the currently chosen option again in the list
        .filter((option) => option.name !== activeLink.name)
        .map((option) => (
            <li
                className="option"
                key={option.name}
                role="option"
                aria-selected={option.active}
            >
                <Link text={option.name} url={option.url} />
            </li>
        ));

    return (
        <div ref={ref} className="listbox-collapsible">
            <p className="_visuallyhidden" id="support-choose-label">
                Choose a support topic:
            </p>
            <button
                className="activeitem"
                aria-haspopup="listbox"
                type="button"
                id="support-active-page"
                aria-labelledby="support-choose-label support-active-page"
                onClick={() => setShowList(!showList)}
            >
                <span className="name">{activeLink.name}</span>
                <Arrow className={arrowClasses} />
            </button>
            <ul
                className="list"
                role="listbox"
                aria-labelledby="support-choose-label"
                {...(showList && { "aria-expanded": true })}
            >
                {links}
            </ul>
        </div>
    );
}
