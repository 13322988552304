import clsx from "clsx";
import React from "react";

export type Props = {
    url: string;
    text: string;
    className?: string;
    isExternal?: boolean;
};

/**
 * A link which when marked as "external" opens in a new tab and adds
 * the proper security attributes
 */
export function Link({ url, text, className, isExternal = false }: Props) {
    // @NOTE: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#security_and_privacy
    const externalProps = {
        rel: "noreferrer noopener",
        target: "_blank",
    };

    const classes = clsx(className && className, "link");

    return (
        <a className={classes} href={url} {...(isExternal && externalProps)}>
            <span className="text">{text}</span>
        </a>
    );
}
