import "./ContentSlider.scss";

import React from "react";

// @ts-ignore
import { Splide, SplideOptions, SplideSlide } from "@splidejs/react-splide";

export type Props = {
    options?: SplideOptions;
    renderControls?: any;
    children: any;
    onPaginationMounted?: (splide: any, prev: any, next: any) => void;
};

export function ContentSlider({
    options = {},
    renderControls = () => {},
    children,
    onPaginationMounted = () => {},
}: Props) {
    const slides = children.map((child: any, index: React.Key) => {
        return <SplideSlide key={index}>{child}</SplideSlide>;
    });

    return (
        <div className="content-slider grid">
            <div className="wrapper">
                <Splide
                    options={options}
                    renderControls={renderControls}
                    onPaginationMounted={onPaginationMounted}
                >
                    {slides}
                </Splide>
            </div>
        </div>
    );
}
