import "./SignupForm.scss";

import React, { FormEvent, useState } from "react";
import { Translations } from "../Newsletter";
import clsx from "clsx";
import { newsletterSignupUrl } from "./constants";
import { getBaseUrl } from "../../../shared/helpers";

export type Props = {
    signupUrl?: string;
    translations: Translations;
};

export type Response = {
    message: string;
    status: string;
};

export function SignupForm({ translations, signupUrl = "" }: Props) {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");

    const messageClass = clsx("message", `-${status}`);

    if (!signupUrl) {
        signupUrl = getBaseUrl() + newsletterSignupUrl;
    }

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
            withCredentials: true,
            body: JSON.stringify({ email: email }),
        };

        fetch(signupUrl, requestOptions)
            .then((res) => res.json())
            .then(
                (data) => {
                    setMessage(data.message);
                    setStatus("success");
                },
                (error: Response) => {
                    setMessage("Something went wrong");
                    setStatus("error");
                }
            );
    };

    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    return (
        <>
            <form onSubmit={onSubmit} className="signup-form">
                <label className="label" htmlFor="newsletter-signup-widget">
                    {translations.email}
                </label>
                <input
                    id="newsletter-signup-widget"
                    className="input"
                    type="email"
                    placeholder={translations.email}
                    required
                    value={email}
                    onInput={onChangeEmail}
                />
                <button className="submit">{translations.submit}</button>
            </form>
            {message && <p className={messageClass}>{message}</p>}
        </>
    );
}
