import "./Mobile.scss";

import React from "react";

import { MenuItem } from "../../../shared/interfaces/MenuItem";
import { ExpandableTreeItem } from "./ExpandableTreeItem/ExpandableTreeItem";
import { TreeItem } from "./TreeItem/TreeItem";
import clsx from "clsx";

export type Props = {
    main: MenuItem[];
    secondary: MenuItem[];
    labelBy: string;
};

/**
 * A tree view for the main navigation in MQ1
 *
 * The user can drill down the navigation by clicking on the categories
 * which then shows or hides the child navigation items.
 *
 * Based on
 * https://www.w3.org/TR/wai-aria-practices/examples/treeview/treeview-2/treeview-2a.html
 */
export function Mobile({ main, secondary, labelBy }: Props) {
    const generateListItem = (items: MenuItem[], root = false) => {
        const list = items.map((item: MenuItem, index) => {
            if (item.children && item.children.length) {
                return (
                    <ExpandableTreeItem key={index} item={item}>
                        {generateListItem(item.children)}
                    </ExpandableTreeItem>
                );
            }

            // No children so just a link and no role
            return <TreeItem key={index} item={item} />;
        });

        const groupClasses = clsx("menu-group", {
            "-root": root,
        });
        return (
            <ul
                {...{
                    className: groupClasses,
                    ...(!root && { role: "group" }),
                    ...(root && { role: "tree", "aria-labelledby": labelBy }),
                }}
            >
                {list}
            </ul>
        );
    };

    return (
        <div>
            {generateListItem(main, true)}
            {generateListItem(secondary, false)}
        </div>
    );
}
