import "./PageHeadline.scss";

import clsx from "clsx";
import React from "react";

import { TextColors } from "../../shared/interfaces/Colors";

export type Props = {
    text: string;
    color: TextColors;
};

export function PageHeadline({ text, color }: Props) {
    const classes = clsx("page-headline", `-${color}`);

    return <h1 className={classes}>{text}</h1>;
}
