import "./CollectionOverview.scss";

import React from "react";

import { useMQ1 } from "../../../../shared/hooks/isMobile";
import { CategoryItem, Collection } from "./CategoryItem/CategoryItem";

// @ts-ignore
import { SplideOptions } from "@splidejs/react-splide";
import { ContentSlider } from "../../../../components/ContentSlider/ContentSlider";

export type Props = {
    collections: Collection[];
};

export function CollectionOverview({ collections }: Props) {
    const isMQ1 = useMQ1();

    const categoriesMarkup = collections?.map((collection, index) => (
        <CategoryItem collection={collection} key={index} />
    ));

    const options: SplideOptions = {
        arrows: true,
        perPage: 1,
        perMove: 1,
        gap: 14,
        pagination: false,
        fixedWidth: "225px",
        type: "loop",
    };

    const sliderMarkup = (
        <ContentSlider options={options}>{categoriesMarkup}</ContentSlider>
    );

    return (
        <section className="collection-overview grid -almost-full">
            <div className="content-wrapper">
                {!isMQ1 && categoriesMarkup}
                {isMQ1 && sliderMarkup}
            </div>
        </section>
    );
}
