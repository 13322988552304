import "./Cta.scss";

import clsx from "clsx";
import React from "react";

import { TextColors } from "../../shared/interfaces/Colors";
import { isExternalLink } from "../../shared/helpers";
import { Link } from "../../elements/Link/Link";

export type Props = {
    color: TextColors;
    link: string;
    text: string;
};

export function Cta({ color, link, text }: Props) {
    const props = {
        url: link,
        text,
        isExternal: isExternalLink(link),
    };
    const classes = clsx("cta", `-${color}`);

    return <Link className={classes} {...props} />;
}
