import "./MiniCart.scss";

import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import { IconLink } from "../../../../elements/IconLink/IconLink";
import { useMiniCartBadge } from "../../../../shared/hooks/MiniCartBadge";
import { ReactComponent as NotificationBadge } from "../../assets/svgs/notification-badge.svg";

export type Props = {
    url: string;
    label: string;
    containsItemsColor: string;
};

export function MiniCart({
    url,
    label,
    containsItemsColor,
    children,
}: PropsWithChildren<Props>) {
    const hasItems = useMiniCartBadge();

    const classes = clsx("bmc-mini-cart", {
        "-has-items": hasItems,
    });

    return (
        <div className={classes}>
            <IconLink url={url} label={label}>
                {children}
                <span className="badge" style={{ color: containsItemsColor }}>
                    <NotificationBadge />
                </span>
            </IconLink>
        </div>
    );
}
