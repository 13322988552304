import "./MoodBoard.scss";

import React from "react";
// @ts-ignore
import { SplideOptions } from "@splidejs/react-splide";
import {
    ResponsivePicture,
    Picture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { useMQ1 } from "../../../../shared/hooks/isMobile";
import { mqsWidth } from "../../../../shared/constants";
import { ImageSlider } from "../../../../components/ImageSlider/ImageSlider";

export type Props = {
    copy: string;
    backgroundImage: Picture;
    leftImage: Picture;
    rightImage: Picture;
};

export function MoodBoard({
    copy,
    backgroundImage,
    leftImage,
    rightImage,
}: Props) {
    const isMQ1 = useMQ1();
    const options: SplideOptions = {
        breakpoints: {},
    };

    const sliderBreakpoint = mqsWidth != null && parseInt(mqsWidth, 10);

    options.breakpoints[sliderBreakpoint] = {
        arrows: true,
        perPage: 1,
        perMove: 1,
        pagination: false,
    };

    const moodboard = (
        <>
            <div className="left-image">
                <ResponsivePicture {...leftImage} />
            </div>
            <div className="right-image">
                <ResponsivePicture {...rightImage} />
            </div>
            <div className="background">
                <ResponsivePicture {...backgroundImage} />
            </div>
        </>
    );
    const pictures = Array.of(leftImage, rightImage, backgroundImage);
    const slider = <ImageSlider pictures={pictures} options={options} />;

    return (
        <div className="mood-board grid -almost-full">
            {copy && <p className="copy">{copy}</p>}
            {isMQ1 && slider}
            {!isMQ1 && moodboard}
        </div>
    );
}
