import "./StickyLinkBar.scss";

import React from "react";
import {
    Brand,
    StickyLink,
    StickyLinkWithIcon,
} from "../StickyLink/StickyLink";
import { ReactComponent as CloseSVG } from "../../assets/svgs/close.svg";
import { useLocalStorageState } from "ahooks";

export type Props = {
    links: StickyLinkWithIcon[];
    brand: Brand;
};

export const LOCALSTORAGE_SUPPORT_NOTIFICATION = "bmc-support-notification";

export function StickyLinkBar({ links, brand }: Props) {
    const [shown, setShown] = useLocalStorageState(
        LOCALSTORAGE_SUPPORT_NOTIFICATION,
        {
            defaultValue: true,
        }
    );

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setShown(false);
    };

    const supportLinks = links.map((link) => (
        <StickyLink key={link.text} brand={brand} {...link} />
    ));

    return shown ? (
        <div className={`sticky-link-bar -${brand}`}>
            <form className="closeform" onSubmit={onSubmit}>
                <button>
                    <CloseSVG className="closesvg" />
                    <span className="_visuallyhidden">Close</span>
                </button>
            </form>
            <div className="links">{supportLinks}</div>
        </div>
    ) : null;
}
