import React from "react";
import { CategoryProduct } from "../CategoryCollection";
import { ResponsivePicture } from "../../ResponsivePicture/ResponsivePicture";

export type Props = {
    product: CategoryProduct;
};

export function CategorySlide({ product }: Props) {
    return (
        <div className="category-slide">
            <a className="product" href={product.url}>
                <ResponsivePicture {...product.productPicture} />
            </a>
        </div>
    );
}
