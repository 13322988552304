import "./TeaserPictures.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../ResponsivePicture/ResponsivePicture";

export type Props = {
    pictures: Picture[];
};

export function TeaserPictures({ pictures }: Props) {
    return (
        <div className="brand-teaser-pictures grid -almost-full">
            <ResponsivePicture {...pictures[0]} />
            <ResponsivePicture {...pictures[1]} />
            <ResponsivePicture {...pictures[2]} />
        </div>
    );
}
