import "./CategoryProfileHeader.scss";

import React from "react";
import { CSSTransition } from "react-transition-group";
import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { ColorText } from "../../../../shared/interfaces/ColorText";
import clsx from "clsx";

export type Props = {
    headline: ColorText;
    subheadline: ColorText;
    copy: ColorText;
    backgroundPicture: Picture;
    foregroundPicture: Picture;
};

export function CategoryProfileHeader({
    headline,
    subheadline,
    copy,
    backgroundPicture,
    foregroundPicture,
}: Props) {
    const headlineClasses = clsx("headline", `-${headline.color}`);
    const subheadlineClasses = clsx("subheadline", `-${subheadline.color}`);
    const copyClasses = clsx("copy", `-${copy.color}`);

    return (
        <section className="adicta-category-profile-header grid -almost-full">
            <CSSTransition
                in
                timeout={300}
                classNames="slideright"
                appear={true}
            >
                <ResponsivePicture {...backgroundPicture} />
            </CSSTransition>
            <CSSTransition
                in
                timeout={500}
                classNames="slideright-portrait"
                appear={true}
            >
                <ResponsivePicture {...foregroundPicture} />
            </CSSTransition>
            <CSSTransition in timeout={500} classNames="fade" appear={true}>
                <div className="content">
                    <h2 className={subheadlineClasses}>{subheadline.text}</h2>
                    <h1 className={headlineClasses}>{headline.text}</h1>
                    <p className={copyClasses}>{copy.text}</p>
                </div>
            </CSSTransition>
        </section>
    );
}
