import "./IconItem.scss";

import React from "react";

import { useInView } from "react-intersection-observer";
import { ProgressBar } from "../ProgressBar/ProgressBar";

import { ReactComponent as HotRides } from "../../../assets/svgs/hot-rides.svg";
import { ReactComponent as LongRides } from "../../../assets/svgs/long-rides.svg";
import { ReactComponent as WetRides } from "../../../assets/svgs/wet-rides.svg";
import { ReactComponent as ColdRides } from "../../../assets/svgs/cold-rides.svg";
import { ReactComponent as GravelRides } from "../../../assets/svgs/gravel-rides.svg";

export enum IntendedIdentifiers {
    HotRides = "hot-rides",
    LongRides = "long-rides",
    WetRides = "wet-rides",
    ColdRides = "cold-rides",
    GravelRides = "gravel-rides",
}

export type Props = {
    identifier: IntendedIdentifiers;
    headline: string;
    fill: number;
    subheadline: string;
};

export function IconItem({ identifier, headline, fill, subheadline }: Props) {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    // @TODO refactor this, make this an object and map over it
    const icon = (identifier: string) => {
        switch (identifier) {
            case "hot-rides":
                return <HotRides />;
            case "long-rides":
                return <LongRides />;
            case "wet-rides":
                return <WetRides />;
            case "cold-rides":
                return <ColdRides />;
            case "gravel-rides":
                return <GravelRides />;
        }
    };

    return (
        <div className="icon-item">
            <div className="icon">{icon(identifier)}</div>
            <h2 className="headline">{headline}</h2>
            <div ref={ref} className="progress">
                {inView && <ProgressBar fill={fill} />}
            </div>
            <p className="text">{subheadline}</p>
        </div>
    );
}
