import { useState, useEffect } from "react";
import { use100vh } from "react-div-100vh";

export function useNaviHeight(padding = 60): number {
    const [height, setHeight] = useState(0);
    const realHeight = use100vh();

    const storeSwitcher = document.querySelector("[class^='page-header-']");
    const bottom = storeSwitcher?.getBoundingClientRect().bottom;

    useEffect(() => {
        if (realHeight && bottom) {
            setHeight(realHeight - bottom - padding);
        }
    }, [bottom, realHeight, padding]);

    return height;
}
