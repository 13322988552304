import "./Notification.scss";

import React, { useContext } from "react";

import { CartContext } from "../../shared/contexts/Cart";

export type Props = {};

export function Notification(props: Props) {
    const { count } = useContext(CartContext);

    return (
        <p className="notification">
            <span className="count">{count}</span>
        </p>
    );
}
