import "./Cta.scss";

import React from "react";
import clsx from "clsx";

export enum CTAColors {
    White = "white",
    Black = "black",
    ClearBlack = "clear-black",
    ClearWhite = "clear-white",
}

export type Props = {
    color: CTAColors;
    text: string;
    link: string;
};

/**
 * @TODO: Create Link element and a CTA HOC
 */
export function Cta({ color, text, link }: Props) {
    const classes = clsx("adicta-cta", `-${color}`);

    return (
        <a className={classes} href={link}>
            {text}
        </a>
    );
}
