import "./UnderlinedLink.scss";

import React from "react";
import { Link, Props as LinkProps } from "../../../../elements/Link/Link";

export type Props = LinkProps;

export function UnderlinedLink(props: LinkProps) {
    return (
        <div className="underlined-link">
            <Link {...props} />
        </div>
    );
}
