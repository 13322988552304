import "./IntendedUse.scss";

import React from "react";

import { IconItem, Props as Icon } from "./IconItem/IconItem";

export type Props = {
    headline: string;
    icons: Icon[];
};

export function IntendedUse({ headline, icons }: Props) {
    const iconMarkup = icons.map((icon, index) => {
        return (
            <IconItem
                key={index}
                identifier={icon.identifier}
                headline={icon.headline}
                fill={icon.fill}
                subheadline={icon.subheadline}
            />
        );
    });

    return (
        <section className="intended-use grid">
            <h1 className="headline">{headline}</h1>
            <div className="items">{iconMarkup}</div>
        </section>
    );
}
