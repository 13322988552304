import "./HomeSlider1.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { Cta, CTAColors, Props as CTA } from "../../elements/Cta/Cta";
import { ColorText } from "../../shared/interfaces/ColorText";
import clsx from "clsx";
import { useMQ1 } from "../../../../shared/hooks/isMobile";

export type Props = {
    headline: ColorText;
    subheadline: ColorText;
    cta: CTA;
    backgroundPicture: Picture;
    foregroundPicture: Picture;
};

export function HomeSlider1({
    headline,
    subheadline,
    cta,
    backgroundPicture,
    foregroundPicture,
}: Props) {
    const isMQ1 = useMQ1();

    // Hint: In Design the headline, subheadline, and CTA should be black on Mobile.
    const headlineColors = isMQ1 ? "-black" : `-${headline.color}`;
    const subHeadlineColors = isMQ1 ? "-black" : `-${headline.color}`;
    const headlineClasses = clsx("headline", headlineColors);
    const subHeadlineClasses = clsx("subheadline", subHeadlineColors);
    const ctaMarkup = isMQ1 ? (
        <Cta text={cta.text} link={cta.link} color={CTAColors.Black} />
    ) : (
        <Cta {...cta} />
    );

    return (
        <section className="adicta-home-slider1 grid -almost-full">
            <div className="background">
                <ResponsivePicture {...backgroundPicture} />
            </div>
            <div className="foreground">
                <ResponsivePicture {...foregroundPicture} />
            </div>
            <div className="content-wrapper">
                <p className={subHeadlineClasses}>{subheadline.text}</p>
                <h1 className={headlineClasses}>{headline.text}</h1>
                <div className="cta-wrapper grid">{ctaMarkup}</div>
            </div>
        </section>
    );
}
