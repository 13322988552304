import "./Gallery.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import { Picture } from "../../../../components/ResponsivePicture/ResponsivePicture";
import { ScorImageSlider, Types } from "../ScorImageSlider/ScorImageSlider";

export type Props = {
    headline?: string;
    pictures: Picture[];
};

export function Gallery({ headline, pictures }: Props) {
    return (
        <section className="scor-gallery">
            {headline && <h1 className="scor-gallery-headline">{headline}</h1>}
            <ScorImageSlider pictures={pictures} type={Types.Bottom} />
        </section>
    );
}
