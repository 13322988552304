import "./ListItem.scss";

import React, { MouseEvent } from "react";

import { AutoExternalLink } from "../../../../elements/AutoExternalLink/AutoExternalLink";

export type Props = {
    onMouseEnter?: (target: MouseEvent<HTMLLIElement>) => void;
    onMouseLeave?: (target: MouseEvent<HTMLLIElement>) => void;
    name: string;
    url: string;
    image: string;
};

export function ListItem({
    onMouseEnter,
    onMouseLeave,
    url,
    name,
    image,
}: Props) {
    return (
        <li
            className="desktop-list-item"
            data-image={image}
            data-current={name}
            {...(onMouseEnter && { onMouseEnter })}
            {...(onMouseLeave && { onMouseLeave })}
        >
            <AutoExternalLink text={name} url={url} />
        </li>
    );
}
