import "./HomeSlider1.scss";

import clsx from "clsx";
import React from "react";

import { TextColors } from "../../shared/interfaces/Colors";
import { Orientation } from "../../shared/interfaces/Orientation";
import { Cta } from "../Cta/Cta";
import { PageHeadline } from "../PageHeadline/PageHeadline";
import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";

export type Props = {
    picture: Picture;
    headline: string;
    subheadline: string;
    ctaText: string;
    ctaLink: string;
    ctaColor: TextColors;
    headlineColor: TextColors;
    subheadlineColor: TextColors;
    type: Orientation;
};

export function HomeSlider1({
    picture,
    headline,
    subheadline,
    ctaLink,
    ctaText,
    headlineColor,
    subheadlineColor,
    ctaColor,
    type,
}: Props) {
    const classes = clsx("home-slider", "grid -almost-full", `-${type}`);
    const mottoClasses = clsx("motto", `-${subheadlineColor}`);

    return (
        <header className={classes}>
            <ResponsivePicture {...picture} />
            <div className="content">
                <PageHeadline text={headline} color={headlineColor} />
                <p className={mottoClasses}>{subheadline}</p>
                <Cta link={ctaLink} text={ctaText} color={ctaColor} />
            </div>
        </header>
    );
}
