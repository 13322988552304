import "./HomeSlider1.scss";

import clsx from "clsx";
import React from "react";

import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { Orientation } from "../../../../shared/interfaces/Orientation";
import { Cta, CTAColors } from "../../elements/Cta/Cta";

export type Props = {
    headline: string;
    headlineColor: string;
    orientation: Orientation;
    backgroundImage: Picture;
    foregroundImage: Picture;
    ctaColor: CTAColors;
    ctaLink: string;
    ctaText: string;
};

export function HomeSlider1({
    headline,
    headlineColor,
    orientation,
    backgroundImage,
    foregroundImage,
    ctaColor,
    ctaLink,
    ctaText,
}: Props) {
    const classes = clsx("scor-home-slider1", `-${orientation}`);

    return (
        <section className={classes}>
            <div className="content scor-grid -almost-full">
                <div className="headline-container">
                    <h1
                        className="page-headline"
                        style={{ color: headlineColor }}
                    >
                        {headline}
                    </h1>
                    <Cta text={ctaText} color={ctaColor} link={ctaLink} />
                </div>
                <ResponsivePicture {...foregroundImage} />
            </div>
            <ResponsivePicture {...backgroundImage} />
        </section>
    );
}
