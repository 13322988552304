import "./List.scss";

import clsx from "clsx";
import React, { MouseEvent } from "react";

import { DesktopMenuItem } from "../../../../shared/interfaces/MenuItem";
import { ListItem } from "../ListItem/ListItem";

export type Props = {
    items: DesktopMenuItem[];
    hidden: boolean;
    onMouseEnter: (target: MouseEvent<HTMLLIElement>) => void;
    onMouseLeave: (target: MouseEvent<HTMLLIElement>) => void;
    parent: string;
};

export function List({
    items,
    hidden,
    onMouseEnter,
    onMouseLeave,
    parent,
}: Props) {
    const classes = clsx("desktop-list", { hidden });
    const listItems = items.map((item: DesktopMenuItem, index: number) => (
        <ListItem
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            name={item.name}
            image={item.image}
            url={item.url}
            key={index}
        />
    ));

    return (
        <ul className={classes} data-parent={parent}>
            {listItems}
        </ul>
    );
}
