import "./TeaserProductStory.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../../../components/ResponsivePicture/ResponsivePicture";
import { Cta, Props as CTA } from "../../elements/Cta/Cta";

export type Props = {
    headline: string;
    headlineColor: string;
    copy: string;
    copyColor: string;
    cta: CTA;
    picture: Picture;
};

export function TeaserProductStory({
    headline,
    headlineColor,
    copy,
    copyColor,
    cta,
    picture,
}: Props) {
    const createMarkup = (copy: string) => {
        return { __html: copy };
    };

    return (
        <section className="scor-teaser-product-story">
            <h1 className="headline" style={{ color: headlineColor }}>
                {headline}
            </h1>
            <ResponsivePicture {...picture} />
            <div className="content">
                <p
                    className="copy"
                    style={{ color: copyColor }}
                    dangerouslySetInnerHTML={createMarkup(copy)}
                />
                <Cta {...cta} />
            </div>
        </section>
    );
}
