/**
 * Tests if the passed string is linking to an external site or if it
 * links to the current domain.
 *
 * This is used in the menu links to open external links in a new tab
 * and add the proper security attributes.
 */
export const isExternalLink = (urlString: string) => {
    try {
        const url = new URL(urlString);

        if (url.origin !== new URL(document.URL, document.baseURI).origin) {
            return true;
        }
    } catch (e) {
        // We don't care about the error
    }

    return false;
};

/**
 * Returns base url with store code.
 * E.g. > https://bmc-switzerland.com/eu_en
 */
export const getBaseUrl = () => {
    // @ts-ignore - Because we can't redefine the window object.
    return window.BASE_URL ? window.BASE_URL : window.location.origin;
};

/**
 * Until Math.clamp finds its way into the browser
 *
 * Taken from https://stackoverflow.com/a/11409978/362253
 */
export function clamp(value: number, lower: number, upper: number) {
    return Math.max(lower, Math.min(value, upper));
}
