import "./Teaser.scss";

import clsx from "clsx";
import React from "react";

import { TextColors } from "../../shared/interfaces/Colors";
import { Cta } from "../Cta/Cta";
import { PageHeadline } from "../PageHeadline/PageHeadline";
import {
    Picture,
    ResponsivePicture,
} from "../ResponsivePicture/ResponsivePicture";

export type Props = {
    picture: Picture;
    headline: string;
    subheadline: string;
    ctaLabel: string;
    ctaUrl: string;
    ctaColor: TextColors;
    headlineColor: TextColors;
    subheadlineColor: TextColors;
    isFullscreen: boolean;
};

export function Teaser({
    picture,
    headline,
    subheadline,
    ctaLabel,
    ctaUrl,
    ctaColor,
    headlineColor,
    subheadlineColor,
    isFullscreen,
}: Props) {
    const mottoClasses = clsx("motto", `-${subheadlineColor}`);
    const sectionClasses = clsx(["makeover-teaser", "grid", "-almost-full"], {
        "-fullscreen": isFullscreen,
    });

    return (
        <section className={sectionClasses}>
            <ResponsivePicture {...picture} />
            <div className="content">
                <p className={mottoClasses}>{subheadline}</p>
                <PageHeadline text={headline} color={headlineColor} />
                <Cta text={ctaLabel} link={ctaUrl} color={ctaColor} />
            </div>
        </section>
    );
}
