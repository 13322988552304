import "./Newsletter.scss";
import { SignupForm } from "./SignupForm/SignupForm";

import React from "react";

export type DataProtection = {
    copy: string;
    link: string;
    linkText: string;
};

export type Translations = {
    email: string;
    submit: string;
};

export type Props = {
    smallHeadline: string;
    secondaryHeadline: string;
    mainHeadline: string;
    signupUrl: string;
    dataProtection: DataProtection;
    translations: Translations;
};

export function Newsletter({
    smallHeadline,
    secondaryHeadline,
    mainHeadline,
    signupUrl,
    dataProtection,
    translations,
}: Props) {
    return (
        <section className="makeover-newsletter grid -copy">
            <div className="content">
                <p className="headline -small">{smallHeadline}</p>
                <h1 className="headline">{mainHeadline}</h1>
                <p className="headline">{secondaryHeadline}</p>
                <SignupForm translations={translations} signupUrl={signupUrl} />
                <p className="privacy">
                    {dataProtection.copy}{" "}
                    <a href={dataProtection.link} className="link">
                        {dataProtection.linkText}
                    </a>
                </p>
            </div>
        </section>
    );
}
