import "./TextModule.scss";

import React from "react";

export type Props = {
    headline?: string;
    copy: string;
};

export function TextModule({ headline, copy }: Props) {
    return (
        <section className="scor-text-module scor-grid -almost-full">
            <div className="scor-text-module-content-wrapper">
                {headline && <h1 className="headline">{headline}</h1>}
                <p className="content">{copy}</p>
            </div>
        </section>
    );
}
