import "./Product.scss";

import React from "react";
import {
    Picture,
    ResponsivePicture,
} from "../../../../../components/ResponsivePicture/ResponsivePicture";
import { UnderlinedLink } from "../../../elements/UnderlinedLink/UnderlinedLink";

type Translations = {
    readMore: string;
};

export type Props = {
    headline: string;
    copy: string;
    link: string;
    picture: Picture;
    translations: Translations;
};

export function Product({
    headline,
    copy,
    link,
    picture,
    translations,
}: Props) {
    return (
        <div className="adicta-product">
            <ResponsivePicture {...picture} />
            <p className="headline">{headline}</p>
            <p className="copy">{copy}</p>
            <UnderlinedLink text={translations.readMore} url={link} />
        </div>
    );
}
