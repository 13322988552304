import React from "react";

export type Props = {};

/**
 * Copies the existing logic/template for Informative Pricing from the template
 * from the rendered page
 *
 * @SEE src/bmc/theme.magento2.bmc.makeover/Magento_Theme/templates/html/topmenu.phtml
 */
export function InformativePricing(props: Props) {
    const currencySwitcherTemplate = document.querySelector(
        "#currency-switcher-template"
    );
    let renderCopyContent = () => ({ __html: "" });

    if (
        currencySwitcherTemplate &&
        // @ts-ignore
        currencySwitcherTemplate.content.firstElementChild
    ) {
        renderCopyContent = () => ({
            // @ts-ignore
            __html: currencySwitcherTemplate.content.firstElementChild
                .innerHTML,
        });
    }

    // This structure is taken directly from the existing Magento 2 implementation
    // so that the styles work properly
    return renderCopyContent().__html ? (
        <div id="header-right" className="header-right">
            <div
                className="switcher currency switcher-currency"
                dangerouslySetInnerHTML={renderCopyContent()}
            />
        </div>
    ) : null;
}
