import "./NotificationBadge.scss";

import React, { PropsWithChildren, useContext } from "react";

import { CartContext } from "../../shared/contexts/Cart";

export type Props = {};

/**
 * The badge shown on the minicart in the navigation if the user has added
 * items to his/her cart.
 *
 * When no items are in the cart then we don't render the component
 */
export function NotificationBadge({ children }: PropsWithChildren<Props>) {
    const { count } = useContext(CartContext);

    const notification = <div className="notification-badge">{children}</div>;

    return count ? notification : null;
}
