import "./Footer.scss";
import { ReactComponent as BmcFooterLogo } from "../../assets/svgs/bmc-logo.svg";

import React from "react";
import { SignupForm } from "../Newsletter/SignupForm/SignupForm";
import { Translations } from "../Newsletter/Newsletter";

export type Props = {
    translations?: Translations;
};

export function Footer({
    translations = { email: "Email", submit: "Submit" },
}: Props) {
    return (
        <footer className="bmc-footer grid">
            <div className="footer-content">
                <div className="footer-col-group">
                    <div className="footer-col -socials">
                        <div className="footer-socials-wrapper">
                            <div className="footer-socials-headline">
                                Follow us
                            </div>
                        </div>
                        <div className="footer-socials-content">
                            <div className="footer-socials-icons"></div>
                            <div className="footer-socials-newsletter">
                                <a
                                    className="newsletter-link"
                                    href="https://link-to-somewhere.de/"
                                >
                                    Newsletter Signup
                                </a>
                                <SignupForm translations={translations} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-col-group">
                    <ul className="footer-col -list">
                        <li className="footer-col-item -headline">Support</li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                    </ul>
                    <ul className="footer-col -list">
                        <li className="footer-col-item -headline">Explore</li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer-col-group">
                    <ul className="footer-col -list">
                        <li className="footer-col-item -headline">Shop</li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                    </ul>
                    <ul className="footer-col -list">
                        <li className="footer-col-item -headline">Company</li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer-col-group">
                    <ul className="footer-col -list">
                        <li className="footer-col-item -headline">Dealer</li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                        <li className="footer-col-item -link">
                            <a
                                className="footer-col-item-link"
                                href="https://link-to-somewhere.de"
                            >
                                Purchase Advise
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom-row grid">
                <BmcFooterLogo className="bmc-logo" />
                <div className="bmc-copyright">
                    Copyright © 2021 BMC Switzerland AG. All rights reserved.
                </div>
                <div className="bmc-legals">
                    <ul className="legal-list">
                        <li className="legal-list-item">
                            <a className="legal-list-link" href="/somewhere">
                                Impressum
                            </a>
                        </li>
                        <li className="legal-list-item">
                            <a className="legal-list-link" href="/somewhere">
                                Disclaimer
                            </a>
                        </li>
                        <li className="legal-list-item">
                            <a className="legal-list-link" href="/somewhere">
                                Privacy
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}
