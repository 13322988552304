// BMC
export const exploreID = "submenu.explore";
export const bikeRegistrationID = "bmc.bike-registration";

// Scor
export const signUpID = "scor.sign-up";
export const followUsID = "scor.follow-us";

export const secondaryMenuIDs = [
    exploreID,
    bikeRegistrationID,
    signUpID,
    followUsID,
];
