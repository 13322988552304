import "./Footer.scss";

import React from "react";

import { Logo } from "../../elements/Logo/Logo";
import { SignupForm } from "../../../../components/Newsletter/SignupForm/SignupForm";

import { ReactComponent as Facebook } from "../../assets/svgs/fb.svg";
import { ReactComponent as Youtube } from "../../assets/svgs/yt.svg";
import { ReactComponent as Instagram } from "../../assets/svgs/ig.svg";
import { ReactComponent as FTP } from "../../assets/svgs/fortheplanet.svg";

import { FooterListItem } from "../../shared/interfaces/FooterItem";
import { List } from "./List/List";
import { ListItem } from "./ListItem/ListItem";
import { IconLink } from "../../../../elements/IconLink/IconLink";

export type Translations = {
    email: string;
    submit: string;
};

export type Props = {
    translations?: Translations;
    items: FooterListItem[];
};

export function Footer({
    translations = { email: "Email", submit: "Submit" },
    items,
}: Props) {
    return (
        <footer className="adicta-footer">
            <div className="top grid -almost-full">
                <div className="social-wrapper grid">
                    <div className="headline">Follow us</div>
                    <div className="social-icons">
                        <IconLink
                            url="https://somewhere.com/"
                            label="Instagram"
                        >
                            <Instagram />
                        </IconLink>
                        <IconLink
                            url="https://somewhere.com/"
                            label="Instagram"
                        >
                            <Youtube />
                        </IconLink>
                        <IconLink
                            url="https://somewhere.com/"
                            label="Instagram"
                        >
                            <Facebook />
                        </IconLink>
                    </div>
                    <div className="headline">Newsletter Signup</div>
                    <div className="newsletter-wrapper">
                        <SignupForm translations={translations} />
                    </div>
                </div>
            </div>
            <div className="content grid -almost-full">
                <List items={items} />
                <div className="ftp">
                    <FTP />
                </div>
            </div>
            <div className="bottom grid -almost-full">
                <Logo />
                <div className="copyright">
                    Copyright © 2021 BMC Switzerland AG. All rights reserved.
                </div>
                <div className="legals">
                    <ul className="legal-list">
                        <ListItem
                            name="Impressum"
                            url="https://somewhere.com/"
                        />
                        <ListItem
                            name="Disclaimer"
                            url="https://somewhere.com/"
                        />
                        <ListItem name="Privacy" url="https://somewhere.com/" />
                    </ul>
                </div>
            </div>
        </footer>
    );
}
