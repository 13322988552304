import "./ExpandableTreeItem.scss";

import React, { PropsWithChildren, useState, MouseEvent } from "react";
import { MenuItem } from "../../../../shared/interfaces/MenuItem";
import { ReactComponent as Arrow } from "../../../../assets/svgs/arrow.svg";

export type Props = {
    item: MenuItem;
};

/**
 * A show/hide component that represents a portion of a tree
 * and hides/shows the children on click
 *
 * Currently only meant for use in MQ1 for the main navigation
 */
export function ExpandableTreeItem({
    item,
    children,
}: PropsWithChildren<Props>) {
    const [expanded, setExpanded] = useState(false);
    const onExpand = (event: MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    return (
        <li
            className="expandable-list-item"
            role="treeitem"
            aria-expanded={expanded}
            onClick={onExpand}
        >
            <div className="label">
                <span className="header">{item.name}</span>
                <Arrow className="arrowsvg" />
            </div>
            {children}
        </li>
    );
}
